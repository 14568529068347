import React, {
  InputHTMLAttributes,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  FiActivity,
  FiPower,
  FiSettings,
  FiUser,
  FiUserCheck,
  FiUsers,
} from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';

import {
  RiDashboardLine,
  RiFile3Line,
  RiMacbookLine,
  RiMailSendLine,
  RiShieldUserLine,
} from 'react-icons/ri';

import { useAuth } from '../../hooks/auth';
import { ROUTES } from '../../routes';

import {
  Container,
  Content,
  ItemMenu,
  Menu,
  MenuSpace,
  ResponsiveMenu,
} from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  currentPage: String;
  setCurrentPage: (newTitle: string) => void;
  setPageTitle: (newTitle: string) => void;
  setPageIcon: (newTitle: any) => void;
  showMenu: boolean;
  showResponsiveMenu: boolean;
  setShowResponsiveMenu: (status: boolean) => void;
}

interface RouteInterface {
  path: string;
  component: any;
  isPrivate: boolean;
  roles: any;
  permissions: any;
}

interface SectionInterface {
  name: string;
}

interface MenuItemsInterface {
  route: string;
  title: string;
  icon: any;
  fixed: boolean;
  section: string;
  showSection: boolean;
  permitted: boolean;
}

const MENU_ITEMS = [
  {
    route: '/main',
    title: 'Dashboard',
    icon: <RiDashboardLine />,
    fixed: true,
    section: '',
    showSection: false,
    permitted: false,
  },
  {
    route: '/contratos',
    title: 'Ver Contratos',
    icon: <RiFile3Line />,
    fixed: false,
    section: 'Contratos',
    showSection: false,
    permitted: false,
  },
  {
    route: '/contratos/edital-flow',
    title: 'Edital Flow',
    icon: <RiFile3Line />,
    fixed: false,
    section: 'Contratos',
    showSection: false,
    permitted: false,
  },
  {
    route: '/contratos/meus-contratos',
    title: 'Meus Contratos',
    icon: <RiFile3Line />,
    fixed: false,
    section: 'Contratos',
    showSection: false,
    permitted: false,
  },
  {
    route: '/contratos/aprovar-contrato',
    title: 'Aprovar Contratos',
    icon: <RiFile3Line />,
    fixed: false,
    section: 'Contratos',
    showSection: false,
    permitted: false,
  },
  {
    route: '/contratos/aprovar-contrato-verba',
    title: 'Aprovar Contratos Verba',
    icon: <RiFile3Line />,
    fixed: false,
    section: 'Contratos',
    showSection: false,
    permitted: false,
  },
  {
    route: '/contratos/permitir-pagamentos',
    title: 'Permitir Pagamentos',
    icon: <RiFile3Line />,
    fixed: false,
    section: 'Contratos',
    showSection: false,
    permitted: false,
  },
  {
    route: '/contratos/comandar-pagamentos',
    title: 'Comandar Pagamentos',
    icon: <RiFile3Line />,
    fixed: false,
    section: 'Contratos',
    showSection: false,
    permitted: false,
  },
  {
    route: '/curadores',
    title: 'Curadores',
    icon: <FiUserCheck />,
    fixed: false,
    section: '',
    showSection: false,
    permitted: false,
  },
  {
    route: '/especialistas',
    title: 'Especialistas',
    icon: <FiUsers />,
    fixed: false,
    section: '',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/contrato-tema',
    title: 'Contratos por Tema',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/contrato-periodo',
    title: 'Contratos por Período',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/contrato-pagamento-permitido',
    title: 'Pagamento Permitido',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/contrato-gerais',
    title: 'Contrato Gerais',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/rh-folha',
    title: 'RH Folha',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/rh-aut-consolidado',
    title: 'RH Aut Consolidado',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/rh-aut-cadastro-individual',
    title: 'RH Aut Cadastro Individual',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/pessoa-juridica-rh',
    title: 'RH Pessoa Jurídica',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/redlist',
    title: 'Redlist',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/contratos-cancelados',
    title: 'Cancelados / Erro',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/relatorio/treinamento',
    title: 'Treinamento',
    icon: <FiActivity />,
    fixed: false,
    section: 'Relatórios',
    showSection: false,
    permitted: false,
  },
  {
    route: '/convidar-usuario',
    title: 'Convidar Especialista',
    icon: <RiMailSendLine />,
    fixed: false,
    section: 'Convites',
    showSection: false,
    permitted: false,
  },
  {
    route: '/convidar-colaborador',
    title: 'Convidar Colaborador',
    icon: <RiMailSendLine />,
    fixed: false,
    section: 'Convites',
    showSection: false,
    permitted: false,
  },
  {
    route: '/treinamento-especialista',
    title: 'Treinamento Conteudista',
    icon: <RiMacbookLine />,
    fixed: false,
    section: 'Treinamentos',
    showSection: false,
    permitted: false,
  },
  {
    route: '/treinamento-curador',
    title: 'Treinamento Curador',
    icon: <RiMacbookLine />,
    fixed: false,
    section: 'Treinamentos',
    showSection: false,
    permitted: false,
  },
  {
    route: '/account/roles',
    title: 'Roles',
    icon: <RiShieldUserLine />,
    fixed: false,
    section: 'Usuários',
    showSection: false,
    permitted: false,
  },
  {
    route: '/account/permissions',
    title: 'Permissions',
    icon: <RiShieldUserLine />,
    fixed: false,
    section: 'Usuários',
    showSection: false,
    permitted: false,
  },
  {
    route: '/account/access-permissions',
    title: 'Permissões de Acesso',
    icon: <RiShieldUserLine />,
    fixed: false,
    section: 'Usuários',
    showSection: false,
    permitted: false,
  },
  {
    route: '/account/users',
    title: 'Ver Usuários',
    icon: <RiShieldUserLine />,
    fixed: false,
    section: 'Usuários',
    showSection: false,
    permitted: false,
  },
  {
    route: '/area-atuacao',
    title: 'Áreas de Atuação',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/areas-conhecimento',
    title: 'Áreas do Conhecimento',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/arquivos-treinamento',
    title: 'Arquivos Treinamento',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/carga',
    title: 'Carga',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  // {
  //   route: "/temas/carga",
  //   title: "Carga de Temas",
  //   icon: <FiSettings />,
  //   fixed: false,
  //   section: "Configurações",
  //   showSection: false,
  //   permitted: false
  // },
  // {
  //   route: "/disciplinas/carga",
  //   title: "Carga de Disciplinas",
  //   icon: <FiSettings />,
  //   fixed: false,
  //   section: "Configurações",
  //   showSection: false,
  //   permitted: false
  // },
  {
    route: '/clientes',
    title: 'Clientes',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/curadorias',
    title: 'Curadorias',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/cursos',
    title: 'Cursos',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/cursos-producao',
    title: 'Cursos Produção',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/disciplinas',
    title: 'Disciplinas',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/disciplinas-producao',
    title: 'Disciplinas Produção',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/formacao',
    title: 'Formação',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/instituicoes',
    title: 'Instituições',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/modelos',
    title: 'Modelos',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/periodos',
    title: 'Períodos',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/producoes',
    title: 'Produções',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/produtos',
    title: 'Produtos',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/profissional',
    title: 'Profissional',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/projetos',
    title: 'Projetos',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/servicos',
    title: 'Serviços',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/status-blacklist',
    title: 'Status de Redlist',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/status-cancelamento',
    title: 'Status de Cancelamento',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/tags',
    title: 'Tags',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/temas',
    title: 'Temas',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/tipos-arquivo',
    title: 'Tipos de Arquivo',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/curadorias/verba',
    title: 'Verba das Curadorias',
    icon: <FiSettings />,
    fixed: false,
    section: 'Configurações',
    showSection: false,
    permitted: false,
  },
  {
    route: '/account/profile',
    title: 'Dados Cadastrais',
    icon: <FiUser />,
    fixed: true,
    section: 'Minha Conta',
    showSection: false,
    permitted: false,
  },
  {
    route: '/account/change-password',
    title: 'Alterar senha',
    icon: <FiUser />,
    fixed: true,
    section: 'Minha Conta',
    showSection: false,
    permitted: false,
  },
];

const Sidebar: React.FC<InputProps> = ({
  currentPage,
  setCurrentPage,
  setPageTitle,
  setPageIcon,
  showMenu,
  showResponsiveMenu,
  setShowResponsiveMenu,
}) => {
  const { signOut, roles, permissions, routes, selectedClient } = useAuth();
  const location = useLocation();

  const [sections] = useState<SectionInterface[]>([
    {
      name: 'Contratos',
    },
    {
      name: 'Relatórios',
    },
    {
      name: 'Configurações',
    },
    {
      name: 'Usuários',
    },
    {
      name: 'Convites',
    },
    {
      name: 'Treinamentos',
    },
    {
      name: 'Minha Conta',
    },
    {
      name: 'Relatórios',
    },
  ]);
  const [menuItems, setMenuItems] = useState<MenuItemsInterface[]>([
    ...MENU_ITEMS,
  ]);
  const [selectedSection, setSelectedSection] = useState('');
  const [routesWithRoles, setRoutesWithRoles] = useState<RouteInterface[]>([]);

  useEffect(() => {
    if (currentPage === '/main') {
      setSelectedSection('');
    }
  }, [currentPage]);

  useEffect(() => {
    if (!routes) return;

    const temp: any = [];

    ROUTES.forEach(route => {
      let current = routes.find((item: any) => item.path === route.path);

      if (current) {
        temp.push({
          ...route,
          roles: current.roles,
        });
      } else {
        temp.push(route);
      }
    });

    setRoutesWithRoles(temp);
  }, [routes]);

  useEffect(() => {
    if (location.pathname) {
      menuItems.forEach(item => {
        if (
          location.pathname === item.route ||
          location.pathname === `${item.route}/`
        ) {
          setCurrentPage(item.route);
          setPageTitle(item.title);
          setPageIcon(item.icon);
          if (item.section) {
            setSelectedSection(item.section);
          }
        }
      });
    }
  }, [location, menuItems]);

  useEffect(() => {
    if (!selectedClient) return;
    if (!selectedClient.id) return;
    const menu_items = menuItems.map((item: any) => {
      item.permitted = false;
      item.showSection = false;
      return item;
    });

    setMenuItems(menu_items);
  }, [selectedClient, routesWithRoles]);

  const checkPermissions: any = useCallback(
    (item: any) => {
      let current: any = routesWithRoles.find(
        route => route.path === item.route,
      );
      // console.log("item", item, current);

      if (!current) return false;

      const result =
        roles && permissions
          ? roles.filter((value: any) => current.roles.includes(value)).length >
              0 ||
            permissions.filter((value: any) =>
              current.permissions.includes(value),
            ).length > 0
          : false;

      item.permitted = result || item.fixed;

      // if(result) {
      //   item.permitted = true;
      // }

      checkSections();

      return result;
    },
    [routesWithRoles, roles, permissions],
  );

  const checkSections: any = useCallback(() => {
    sections.forEach(item => {
      let current: any = menuItems.find(menuItem => {
        return menuItem.section === item.name && menuItem.permitted;
      });

      if (current) {
        // console.log("checkSections", current);
        current.showSection = true;
      }
    });
  }, [menuItems, sections]);

  return (
    <>
      <MenuSpace showMenu={showMenu} />

      <Container showMenu={showMenu}>
        <Content>
          <Menu>
            {menuItems.map(item => {
              if (checkPermissions(item) || item.fixed) {
                // {console.log("menuItems item:", item)}
                return (
                  <div key={`${item.section}-${item.route}`}>
                    {item.section && item.showSection && (
                      <ItemMenu
                        currentPage={
                          item.section
                            ? item.section === selectedSection
                            : false
                        }
                        showItem={true}
                      >
                        <Link
                          to={item.route}
                          onClick={() => {
                            setCurrentPage(item.route);
                            setPageTitle(item.title);
                            setPageIcon(item.icon);
                            setSelectedSection(item.section);
                          }}
                        >
                          {item.icon}
                          <h2>{item.section}</h2>
                        </Link>
                      </ItemMenu>
                    )}

                    {selectedClient && selectedClient.nome ? (
                      <ItemMenu
                        key={item.route}
                        currentPage={currentPage === item.route}
                        showItem={
                          item.section ? item.section === selectedSection : true
                        }
                      >
                        <Link
                          to={item.route}
                          className={`${item.section ? 'no-show' : ''}`}
                          onClick={() => {
                            setCurrentPage(item.route);
                            setPageTitle(item.title);
                            setPageIcon(item.icon);
                            setSelectedSection(
                              item.section ? item.section : '',
                            );
                          }}
                        >
                          {item.icon}
                          {selectedClient.nome === 'EnsineMe' ? (
                            <h2>{item.title}</h2>
                          ) : (
                            <h2>
                              {item.route === '/relatorio/contrato-periodo'
                                ? 'Contratos'
                                : item.title}
                            </h2>
                          )}
                        </Link>
                      </ItemMenu>
                    ) : (
                      <>
                        {item.fixed && (
                          <ItemMenu
                            key={item.route}
                            currentPage={currentPage === item.route}
                            showItem={
                              item.section
                                ? item.section === selectedSection
                                : true
                            }
                          >
                            <Link
                              to={item.route}
                              className={`${item.section ? 'no-show' : ''}`}
                              onClick={() => {
                                setCurrentPage(item.route);
                                setPageTitle(item.title);
                                setPageIcon(item.icon);
                                setSelectedSection(
                                  item.section ? item.section : '',
                                );
                              }}
                            >
                              {item.icon}
                              <h2>{item.title}</h2>
                            </Link>
                          </ItemMenu>
                        )}
                      </>
                    )}
                  </div>
                );
              }
            })}
          </Menu>
        </Content>
      </Container>

      <ResponsiveMenu showMenu={showResponsiveMenu}>
        <Menu>
          {menuItems.map(item => {
            if (checkPermissions(item) || item.fixed) {
              return (
                <div key={`${item.section}-${item.route}-div-mobile`}>
                  {item.section && item.showSection && (
                    <ItemMenu
                      key={`${item.section}-${item.route}`}
                      currentPage={
                        item.section ? item.section === selectedSection : false
                      }
                      showItem={true}
                    >
                      <Link
                        to={item.route}
                        onClick={() => {
                          setCurrentPage(item.route);
                          setPageTitle(item.title);
                          setPageIcon(item.icon);
                          setSelectedSection(item.section);
                        }}
                      >
                        {item.icon}
                        <h2>{item.section}</h2>
                      </Link>
                    </ItemMenu>
                  )}

                  <ItemMenu
                    key={item.route}
                    currentPage={currentPage === item.route}
                    showItem={
                      item.section ? item.section === selectedSection : true
                    }
                  >
                    <Link
                      to={item.route}
                      className={`${item.section ? 'no-show' : ''}`}
                      onClick={() => {
                        setCurrentPage(item.route);
                        setPageTitle(item.title);
                        setPageIcon(item.icon);
                        setSelectedSection(item.section ? item.section : '');
                        setShowResponsiveMenu(false);
                      }}
                    >
                      {item.icon}
                      <h2>{item.title}</h2>
                    </Link>
                  </ItemMenu>
                </div>
              );
            }
          })}

          <ItemMenu currentPage={currentPage === '/logout'} showItem={true}>
            <a
              onClick={() => {
                setShowResponsiveMenu(false);
                signOut();
              }}
            >
              <FiPower />
              <h2>Sair</h2>
            </a>
          </ItemMenu>
        </Menu>
      </ResponsiveMenu>
    </>
  );
};

export default Sidebar;
