import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiArrowLeft, FiHash, FiLock, FiMail } from 'react-icons/fi';
import { Link, useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Button from '../../../../components/Button';
import Input from '../../../../components/Input';

import { AnimationContainer, Background, Container, Content } from './styles';

import logoImage from '../../../../assets/logo.png';

interface SignUpFormData {
  // name: string;
  hash: string;
  email: string;
  password: string;
  password_confirmation: string;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const query = useQuery();

  const { signIn } = useAuth();

  const [loading, setLoading] = useState(false);

  const { addToast } = useToast();

  useEffect(() => {
    const hash = query.get('hash');

    getHashEmail(hash);
    formRef.current?.setFieldValue('hash', hash);
  }, []);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        if (data.email) {
          data.email = data.email.toLowerCase();
        }

        const schema = Yup.object().shape({
          // name: Yup.string().required('Nome obrigatório'),
          hash: Yup.string().required('Código de acesso obrigatório'),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          password: Yup.string().min(6, 'Mínimo de 6 caracteres'),
          password_confirmation: Yup.string()
            .required('Confirmação de senha obrigatória')
            .oneOf(
              [Yup.ref('password'), ''],
              'A confirmação de senha não confere',
            ),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const result = await api.post('/usuario', {
          ...data,
          // redirect_url: 'https://contrato.ensineme.com.br/confirm-email',
          // redirect_url: 'http://localhost:3000/confirm-email',
          redirect_url: `https://${window.location.hostname}/confirm-email`,
        });

        Swal.fire({
          title: `${
            result.data?.confirmed_email
              ? '<strong>Parabéns!</strong>'
              : '<strong>Estamos quase lá!</strong>'
          }`,
          icon: 'info',
          html: `${
            result.data?.confirmed_email
              ? 'Seja bem vindo ao EnsineMe Flow!'
              : 'Confirme seu email para ter acesso ao Flow!'
          }`,
          showCloseButton: true,
          showCancelButton: false,
          focusConfirm: true,
          confirmButtonText: 'Ok',
          confirmButtonAriaLabel: 'Ok',
          cancelButtonText: '',
          cancelButtonAriaLabel: '',
        });

        // addToast({
        //   type: 'success',
        //   title: result.data?.confirmed_email ? 'Parabéns!' :
        //     'Estamos quase lá!',
        //   description: result.data?.confirmed_email ? 'Seja bem vindo a Plataforma Yduqs Flow!' :
        //     'Confirme seu email para ter acesso ao Flow!',
        // });

        const response = await signIn({
          email: data.email,
          password: data.password,
        });

        if (response) {
          history.push('/main');
        } else {
          history.push('/signin');
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: err.response?.data[error]?.message
              ? 'E-mail já cadastrado'
              : 'Ocorreu um erro ao fazer cadastro, tente novamente.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [addToast, history],
  );

  const getHashEmail = useCallback(async hash => {
    try {
      setLoading(true);

      if (hash.length >= 7) {
        const response = await api.get(`convite/${hash}`);
        //console.log("response", response);

        if (response.data) {
          formRef.current?.setFieldValue('email', response.data);
        }
      } else {
        formRef.current?.setFieldValue('email', '');
      }
    } catch (err) {
      //console.log(err.response);
      formRef.current?.setFieldValue('email', '');
    } finally {
      setLoading(false);
    }
  }, []);

  return (
    <Container>
      <Background />
      <Content>
        <AnimationContainer>
          <img src={logoImage} alt="EnsineMe – Flow" />

          <Form ref={formRef} onSubmit={handleSubmit}>
            <h1>Faça seu cadastro</h1>

            {/* <Input name="name" type="text" icon={FiUser} placeholder="Nome" /> */}

            <Input
              name="hash"
              type="text"
              icon={FiHash}
              placeholder="Código de Acesso"
              onChange={evt => getHashEmail(evt.target.value)}
            />
            <Input
              name="email"
              type="text"
              icon={FiMail}
              placeholder="E-mail"
              disabled
            />
            <Input
              name="password"
              type="password"
              icon={FiLock}
              placeholder="Senha"
              autoComplete="off"
            />
            <Input
              name="password_confirmation"
              type="password"
              icon={FiLock}
              placeholder="Confirme a senha"
              autoComplete="off"
            />

            <Button type="submit" loading={loading}>
              Cadastrar
            </Button>
          </Form>

          <Link to="/signin">
            <FiArrowLeft />
            Voltar para login
          </Link>
        </AnimationContainer>
      </Content>
    </Container>
  );
};

export default SignUp;
