import { FormHandles } from '@unform/core';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DataTable from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import { Form } from '@unform/web';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';
// import FormInputMaskSimple from '../../components/FormInputMaskSimple';
// import DateRangePicker from '../../components/DateRangePicker';

import api from '../../services/api';

import { WrapRow } from '../_modules/Account/Roles/styles';
import { ClearButton, Container, Content, TextField } from './styles';

interface DataInterface {
  id: string;
  hash: string;
  tema: any;
  especialista: any;
  tema_curador_responsavel: string;
  curadoria: string;
  motivo: string;
  tipo_contratacao: string;
}

interface PeriodosInterface {
  id: any;
  periodo: string;
}

interface OptionsData {
  value: any;
  label: string;
}

const RelatorioContratoPeriodo: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { token, selectedClient } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  var date = new Date();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DataInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [periodos, setPeriodos] = useState<PeriodosInterface[]>([]);
  const [optionsPeriodos, setOptionsPeriodos] = useState<OptionsData[]>([]);
  const [selectedPeriodo, setSelectedPeriodo] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [startDate, setStartDate] = useState<any>(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [endDate, setEndDate] = useState<any>(
    new Date(date.getFullYear(), date.getMonth() + 1, 0),
  );
  const [dataInicioPermissaoCorte, setDataInicioPermissaoCorte] = useState('');
  const [dataFimPermissaoCorte, setDataFimPermissaoCorte] = useState('');

  useEffect(() => {
    getPeriodos();
  }, []);

  useEffect(() => {
    if (!startDate) return;
    if (!endDate) return;
    if (selectedClient.tem_periodo) return;

    getReport();
  }, [startDate, endDate, selectedClient]);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false);
    }
  }, [clearSelectedRows]);

  useEffect(() => {
    var options: OptionsData[] = [];

    periodos.map(item => {
      options.push({ value: item.id, label: item.periodo });
    });

    setOptionsPeriodos(options);
    //console.log("options periodos", options);
  }, [periodos]);

  useEffect(() => {
    if (selectedPeriodo && selectedPeriodo.value) {
      getReport();
    }
  }, [selectedPeriodo]);

  const filtered = useMemo(() => {
    if (!filterText) return data;

    let search = filterText.toLowerCase();

    search = search.split('-').join('');
    search = search.split('.').join('');

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    return data.filter((item: any) => {
      return (
        item.tema
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        // item.hash.toLowerCase().includes(search) ||
        item.especialista
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        item.curador_responsavel
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        item.projeto
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search) ||
        item.curadoria
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(search)
      );
    });
  }, [filterText, data]);

  const getPeriodos = useCallback(async () => {
    try {
      setLoading(true);

      const response = await api.get(`/periodo`);
      //console.log("response periodos", response.data);

      setPeriodos(response.data ? response.data : []);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getReport = useCallback(async () => {
    try {
      setLoading(true);

      //console.log("id_periodo", selectedPeriodo.value);

      const params: any = {
        id_cliente: selectedClient.id,
      };

      if (selectedClient.tem_periodo) {
        params.id_periodo = selectedPeriodo.value;
      } else {
        if (!startDate) return;
        if (!endDate) return;

        const data_inicio_permissao_corte = `${startDate.getFullYear()}-${(
          '0' +
          (startDate.getMonth() + 1)
        ).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`;
        const data_fim_permissao_corte = `${endDate.getFullYear()}-${(
          '0' +
          (endDate.getMonth() + 1)
        ).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`;

        setDataInicioPermissaoCorte(data_inicio_permissao_corte);
        setDataFimPermissaoCorte(data_fim_permissao_corte);

        params.data_inicio_permissao_corte = data_inicio_permissao_corte;
        params.data_fim_permissao_corte = data_fim_permissao_corte;
      }

      const response = await api.get(`/relatorio-contrato/1`, {
        params,
      });
      // console.log("response", response.data);

      setData(response.data ? response.data : []);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedPeriodo, selectedClient, startDate, endDate]);

  const downloadReport = useCallback(async () => {
    try {
      setLoading(true);

      const params: any = {
        id_cliente: selectedClient.id,
      };

      if (selectedClient.tem_periodo) {
        params.id_periodo = selectedPeriodo.value;
      } else {
        if (!startDate) return;
        if (!endDate) return;

        const data_inicio_permissao_corte = `${startDate.getFullYear()}-${(
          '0' +
          (startDate.getMonth() + 1)
        ).slice(-2)}-${('0' + startDate.getDate()).slice(-2)}`;
        const data_fim_permissao_corte = `${endDate.getFullYear()}-${(
          '0' +
          (endDate.getMonth() + 1)
        ).slice(-2)}-${('0' + endDate.getDate()).slice(-2)}`;

        setDataInicioPermissaoCorte(data_inicio_permissao_corte);
        setDataFimPermissaoCorte(data_fim_permissao_corte);

        params.data_inicio_permissao_corte = data_inicio_permissao_corte;
        params.data_fim_permissao_corte = data_fim_permissao_corte;
      }

      //console.log("params", params);

      const response = await api.post(`/relatorio-contrato`, params);
      //console.log("response", response.data);

      if (response.data) {
        window.open(`${response.data}?token=${token}`, '_blank');
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedPeriodo, selectedClient, startDate, endDate]);

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <div
      className="table-action-button"
      onClick={() => {
        downloadReport();
      }}
    >
      {/* <FiPlus /> */}
      <span>Download</span>
    </div>
  );

  function handleChangeSelectedPeriodo(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue Periodo", newValue.value);

    setSelectedPeriodo(newValue);
  }

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    const CustomInputDatePicker = forwardRef(
      ({ value, onClick }: any, ref: any) => (
        <button className="custom-input-datepicker" onClick={onClick} ref={ref}>
          {value}
        </button>
      ),
    );

    return (
      <WrapRow>
        {selectedClient.tem_periodo ? (
          <>
            <span className="label-datapicker-input">Período:</span>

            <div style={{ width: '150px' }}>
              <Select
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: provided => ({ ...provided, zIndex: 9999 }),
                  // input: styles => ({ ...styles }),
                }}
                placeholder="Período"
                onChange={handleChangeSelectedPeriodo}
                options={optionsPeriodos}
                value={selectedPeriodo}
              />
            </div>
          </>
        ) : (
          <>
            <span className="label-datapicker-input">Início:</span>
            <DatePicker
              closeOnScroll={true}
              selected={startDate}
              // onChange={(date) => setStartDate(date)}
              onChange={date => setStartDate(date)}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInputDatePicker />}
            />
            <span className="label-datapicker-input">Término:</span>
            <DatePicker
              closeOnScroll={true}
              selected={endDate}
              onChange={date => setEndDate(date)}
              dateFormat="dd/MM/yyyy"
              customInput={<CustomInputDatePicker />}
            />
          </>
        )}

        <div style={{ flex: 1 }}></div>
        <TextField
          id="search"
          type="text"
          placeholder="Pesquisar"
          aria-label="Search Input"
          value={filterText}
          onChange={(e: any) => setFilterText(e.target.value)}
        />
        <ClearButton type="button" onClick={handleClear}>
          x
        </ClearButton>
      </WrapRow>
    );
  }, [
    filterText,
    resetPaginationToggle,
    selectedPeriodo,
    handleChangeSelectedPeriodo,
  ]);

  let parseText = function (text: string, limit: number) {
    if (text.length > limit)
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' ' &&
          (text.charAt(i - 1) != ',' ||
            text.charAt(i - 1) != '.' ||
            text.charAt(i - 1) != ';')
        ) {
          return text.substring(0, i) + '...';
        }
      }
    else return text;
  };

  const columns = [
    {
      name: 'ID',
      selector: 'hash',
      sortable: false,
      maxWidth: '140px',
      // right: true,
    },
    {
      name: 'Produção',
      selector: 'producao',
      sortable: false,
      maxWidth: '300px',
      // right: true,
      cell: (row: any) => {
        let producao = row.tema
          ? row.tema
          : row.disciplina
          ? row.disciplina
          : row.curso;
        let producao_id =
          row.tema && row.tema.id
            ? row.tema.id
            : row.disciplina_producao
            ? row.disciplina_producao?.disciplina?.id
            : row.curso_producao?.curso?.id;

        if (!producao) return '';

        let dots_string = String(producao).substring(0, 50);
        let reduce = dots_string !== producao;
        return (
          <>
            {reduce ? (
              <>
                <div data-tip data-for={`${producao_id}`}>
                  {dots_string}
                </div>
                <ReactTooltip id={`${producao_id}`} effect="solid">
                  <span>{producao}</span>
                </ReactTooltip>
              </>
            ) : (
              <div>{dots_string}</div>
            )}
          </>
        );
      },
    },
    {
      name: 'Tipo de Produção',
      selector: 'tipo',
      sortable: false,
      width: '120px',
      hide: 1465,
      // right: true,
      cell: (row: any) => {
        // let tipo = (row.tema) ? "Tema" : row.disciplina_producao ? "Disciplina" : "Curso";
        return <span>{row.tipo}</span>;
      },
    },
    {
      name: 'Tipo de Contrato',
      selector: 'tipo_contrato',
      sortable: false,
      width: '120px',
      hide: 1350,
      // right: true,
      cell: (row: any) => {
        let tipo =
          row.tipo_contrato === 'producao_atual'
            ? 'Produção'
            : row.tipo_contrato === 'manutencao_reativa'
            ? 'Manutenção Reativa'
            : row.tipo_contrato === 'manutencao_proativa'
            ? 'Manutenção Proativa'
            : row.tipo_contrato === 'manutencao_proativa_direcionada'
            ? 'Manutenção Proativa Direcionada'
            : row.tipo_contrato === 'terceirizado'
            ? 'Terceirizado'
            : row.tipo_contrato === 'prestacao_servico'
            ? 'Prestação de Serviço'
            : row.tipo_contrato.replace('_', ' ')[0].toUpperCase() +
              row.tipo_contrato.replace('_', ' ').substring(1);
        return <span>{tipo}</span>;
      },
    },
    {
      name: 'Especialista',
      selector: 'especialista',
      sortable: false,
      // right: true,
    },

    {
      name: selectedClient.tem_curadoria ? 'Curadoria' : 'Área de Atuação',
      selector: selectedClient.tem_curadoria ? 'curadoria' : 'area_atuação',
      sortable: false,
      // right: true,
      maxWidth: '140px',
      cell: (row: any) => {
        if (selectedClient.tem_curadoria) {
          return <span>{row.curadoria}</span>;
        } else {
          return <span>{row.area_atuacao}</span>;
        }
      },
    },
    {
      name: selectedClient.tem_curadoria ? 'Curador' : 'Responsável',
      selector: 'curador_responsavel',
      sortable: false,
      // right: true,
    },
    {
      name: 'Projeto',
      selector: 'projeto',
      sortable: false,
      maxWidth: '200px',
      // right: true,
    },
    {
      name: 'Motivo do Cancelamento',
      selector: 'descricao',
      sortable: false,
      // maxWidth: '400px',
      // right: true,
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <DataTable
            title={
              selectedClient.tem_periodo
                ? 'Relatório - Contratos por Período'
                : 'Relatório - Contratos'
            }
            columns={columns}
            data={filtered}
            // selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            // defaultSortField="especialista.email"
            pagination
            actions={actions}
            // contextActions={contextActions}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de',
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: '',
            }}
            progressComponent={<Loading />}
            progressPending={loading}
            subHeader
            subHeaderComponent={subHeaderComponent}
            paginationResetDefaultPage={resetPaginationToggle}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default RelatorioContratoPeriodo;
