import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { cnpj } from 'cpf-cnpj-validator';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiX } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import Switch from 'react-switch';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import getValidationErrors from '../../utils/getValidationErrors';

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Button from '../../components/Button';
import Card from '../../components/Card';
import FormInput from '../../components/FormInput';
import FormInputMask from '../../components/FormInputMask';

import api from '../../services/api';

import { BsFileEarmarkText, BsFileRichtext } from 'react-icons/bs';
import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';
import {
  Container,
  Content,
  ContratoData,
  TableButtonAccepted,
  TableButtonRefused,
  WrapRow,
  WrapRowItem,
} from './styles';

interface AceitarContratosInterface {
  id: string;
  data_contrato: string;
  prazo_entrega: string;
  data_prestacao_servico: string;
  tema: any;
  observacoes: string;
  produtos: any;
  especialista: any;
  remetente: any;
  valor_total: any;
  hash: string;
  tipo_contratacao: string;
  contatos: any;
  arquivo_contrato: any;
  arquivo_direito_imagem: any;
  disciplina_producao: any;
  curso_producao: any;
  tipo_contrato: string;
  cliente: any;
}

interface DadosPagamentoFormData {
  id: string;
  banco: string;
  numero_banco: string;
  agencia: string;
  chave_pix: string;
  conta: string;
  conta_pessoa_fisica: boolean;
  digito_verificador: string;
  id_usuario: string;
  nome: string;
  cpf: string;
  email: string;
  telefone: string;
  celular: string;
  cnpj: string;
  razao_social: string;
  empresa_rua: string;
  empresa_numero: string;
  empresa_complemento: string;
  empresa_bairro: string;
  empresa_cep: string;
  empresa_cidade: string;
  empresa_estado: string;
}

interface BanksInterface {
  id: any;
}

interface OptionsData {
  value: any;
  label: string;
}

const AceitarContratos: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const formRef_PF = useRef<FormHandles>(null);
  const formRef_PJ = useRef<FormHandles>(null);

  const history = useHistory();
  const location: any = useLocation();

  const { token, roles } = useAuth();
  const { addToast } = useToast();

  // const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(false);
  const [selectedContrato, setSelectedContrato] = useState<
    AceitarContratosInterface
  >({} as AceitarContratosInterface);
  const [selectedParecer, setSelectedParecer] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [selectedItem, setSelectedItem] = useState<AceitarContratosInterface>(
    {} as AceitarContratosInterface,
  );
  const [showFormAceite, setShowFormAceite] = useState(false);
  const [showFormPF, setShowFormPF] = useState(false);
  const [showFormPJ, setShowFormPJ] = useState(false);
  const [showFormInterno, setShowFormInterno] = useState(false);
  const [dadosPagamento, setDadosPagamento] = useState<DadosPagamentoFormData>(
    {} as DadosPagamentoFormData,
  );
  const [showErrorCNAE, setShowErrorCNAE] = useState(false);
  const [contratoConfirmado, setContratoConfirmado] = useState(false);
  const [contratoAceito, setContratoAceito] = useState(false);
  const [matricula, setMatricula] = useState('');
  const [cnpjNotFound, setCnpjNotFound] = useState(false);
  const [isEspecialista, setIsEspecialista] = useState(false);
  const [banks, setBanks] = useState<BanksInterface[]>([]);
  const [selectedBank, setSelectedBank] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [optionsBank, setOptionsBank] = useState<OptionsData[]>([]);

  useEffect(() => {
    if (location && location.state) {
      setSelectedItem(location.state.selectedItem);
      getBanks();
    }
  }, [location]);

  useEffect(() => {
    if (roles.length > 0) {
      if (roles.indexOf('especialista') > -1) {
        setIsEspecialista(true);
      }
    }
  }, [roles]);

  useEffect(() => {
    if (banks) {
      const tmp: OptionsData[] = [];

      banks.forEach((item: any) => {
        if (item.code) {
          tmp.push({
            value: item.code,
            label: `${item.code} - ${item.fullName}`,
            // code: item.code
          });
        }
      });

      setOptionsBank(tmp);
    }
  }, [banks]);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      getContrato(selectedItem.id);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedContrato && selectedContrato.id) {
      if (selectedContrato.tipo_contratacao === 'interno') {
        setShowFormInterno(true);
        setShowFormAceite(true);

        setMatricula(selectedContrato.especialista?.dados_usuario?.matricula);
        //console.log("matricula", selectedContrato.especialista?.dados_usuario?.matricula);
      }

      if (selectedContrato.arquivo_contrato) {
        setContratoConfirmado(true);
      }

      getDadosPagamento(selectedContrato.hash);
    }
  }, [selectedContrato]);

  useEffect(() => {
    if (showFormPF) {
      formRef_PF.current?.setFieldValue(
        'nome',
        selectedContrato.especialista?.dados_usuario?.nome,
      );
      formRef_PF.current?.setFieldValue(
        'cpf',
        selectedContrato.especialista?.dados_usuario?.cpf,
      );
      formRef_PF.current?.setFieldValue(
        'email',
        selectedContrato.especialista?.email,
      );

      if (selectedContrato.especialista?.contatos) {
        // console.log(selectedContrato.especialista?.contatos);

        const tel = selectedContrato.especialista?.contatos.find(
          (item: any) => item.tipo === 'telefone',
        );
        const cel = selectedContrato.especialista?.contatos.find(
          (item: any) => item.tipo === 'celular',
        );

        formRef_PF.current?.setFieldValue('telefone', tel ? tel.conteudo : '');
        formRef_PF.current?.setFieldValue('celular', cel ? cel.conteudo : '');
      }

      // formRef_PF.current?.setFieldValue("telefone", selectedContrato.contatos?.telefone ? selectedContrato.contatos.telefone : "");
      // formRef_PF.current?.setFieldValue("celular", selectedContrato.contatos?.celular ? selectedContrato.contatos.celular : "");

      // formRef_PF.current?.setFieldValue("banco", dadosPagamento.banco);
      formRef_PF.current?.setFieldValue('agencia', dadosPagamento.agencia);
      formRef_PF.current?.setFieldValue('conta', dadosPagamento.conta);
      formRef_PF.current?.setFieldValue(
        'digito_verificador',
        dadosPagamento.digito_verificador,
      );

      setSelectedBank(
        dadosPagamento.banco && dadosPagamento.numero_banco
          ? {
              value: dadosPagamento.numero_banco,
              label: `${dadosPagamento.numero_banco} - ${dadosPagamento.banco}`,
            }
          : ({} as OptionsData),
      );
    }
  }, [showFormPF, selectedContrato]);

  useEffect(() => {
    if (showFormPJ) {
      formRef_PJ.current?.setFieldValue(
        'nome',
        selectedContrato.especialista?.dados_usuario?.nome,
      );
      formRef_PJ.current?.setFieldValue(
        'cpf',
        selectedContrato.especialista?.dados_usuario?.cpf
          ? selectedContrato.especialista?.dados_usuario?.cpf
          : '',
      );
      formRef_PJ.current?.setFieldValue(
        'email',
        selectedContrato.especialista?.email,
      );

      if (selectedContrato.especialista?.contatos) {
        const tel = selectedContrato.especialista?.contatos.find(
          (item: any) => item.tipo === 'telefone',
        );
        const cel = selectedContrato.especialista?.contatos.find(
          (item: any) => item.tipo === 'celular',
        );

        formRef_PJ.current?.setFieldValue('telefone', tel ? tel.conteudo : '');
        formRef_PJ.current?.setFieldValue('celular', cel ? cel.conteudo : '');
      }

      // formRef_PJ.current?.setFieldValue("banco", dadosPagamento.banco);
      formRef_PJ.current?.setFieldValue('agencia', dadosPagamento.agencia);
      formRef_PJ.current?.setFieldValue('conta', dadosPagamento.conta);
      formRef_PJ.current?.setFieldValue(
        'digito_verificador',
        dadosPagamento.digito_verificador,
      );
      formRef_PJ.current?.setFieldValue(
        'cnpj',
        selectedContrato.especialista?.dados_usuario?.pj_cnpj
          ? selectedContrato.especialista?.dados_usuario?.pj_cnpj
          : '',
      );

      setSelectedBank(
        dadosPagamento.banco && dadosPagamento.numero_banco
          ? {
              value: dadosPagamento.numero_banco,
              label: `${dadosPagamento.numero_banco} - ${dadosPagamento.banco}`,
            }
          : ({} as OptionsData),
      );

      // if (selectedContrato.especialista?.dados_usuario?.pj_cnpj) {
      //   searchCNPJ(selectedContrato.especialista?.dados_usuario?.pj_cnpj);
      // }

      formRef_PJ.current?.setFieldValue(
        'razao_social',
        selectedContrato.especialista?.dados_usuario?.pj_razao_social
          ? selectedContrato.especialista?.dados_usuario.pj_razao_social
          : '',
      );
      formRef_PJ.current?.setFieldValue(
        'empresa_cep',
        selectedContrato.especialista?.dados_usuario?.pj_cep
          ? selectedContrato.especialista?.dados_usuario.pj_cep
          : '',
      );
      formRef_PJ.current?.setFieldValue(
        'empresa_rua',
        selectedContrato.especialista?.dados_usuario?.pj_rua
          ? selectedContrato.especialista?.dados_usuario.pj_rua
          : '',
      );
      formRef_PJ.current?.setFieldValue(
        'empresa_numero',
        selectedContrato.especialista?.dados_usuario?.pj_numero
          ? selectedContrato.especialista?.dados_usuario.pj_numero
          : '',
      );
      formRef_PJ.current?.setFieldValue(
        'empresa_complemento',
        selectedContrato.especialista?.dados_usuario?.pj_complemento
          ? selectedContrato.especialista?.dados_usuario.pj_complemento
          : '',
      );
      formRef_PJ.current?.setFieldValue(
        'empresa_cidade',
        selectedContrato.especialista?.dados_usuario?.pj_cidade
          ? selectedContrato.especialista?.dados_usuario.pj_cidade
          : '',
      );
      formRef_PJ.current?.setFieldValue(
        'empresa_bairro',
        selectedContrato.especialista?.dados_usuario?.pj_bairro
          ? selectedContrato.especialista?.dados_usuario.pj_bairro
          : '',
      );
      formRef_PJ.current?.setFieldValue(
        'empresa_estado',
        selectedContrato.especialista?.dados_usuario?.pj_estado
          ? selectedContrato.especialista?.dados_usuario.pj_estado
          : '',
      );
    }
  }, [showFormPJ, selectedContrato]);

  useEffect(() => {
    if (matricula) {
      setMatricula(matricula.trim());
    }
  }, [matricula]);

  const dataContrato = useMemo(() => {
    if (selectedContrato.data_contrato) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_contrato),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPrazoEntrega = useMemo(() => {
    if (selectedContrato.prazo_entrega) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.prazo_entrega),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPrestacaoServico = useMemo(() => {
    if (selectedContrato.data_prestacao_servico) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_prestacao_servico),
      );

      const hora_split = selectedContrato.data_prestacao_servico
        .split('T')[1]
        .split(':');
      const hora = `${hora_split[0]}:${hora_split[1]}`;

      // console.log("hora", hora )

      if (hora !== '00:00') {
        return `${('0' + dt.getDate()).slice(-2)}/${(
          '0' +
          (dt.getMonth() + 1)
        ).slice(-2)}/${dt.getFullYear()} às ${hora}`;
      } else {
        return `${('0' + dt.getDate()).slice(-2)}/${(
          '0' +
          (dt.getMonth() + 1)
        ).slice(-2)}/${dt.getFullYear()}`;
      }
    }
  }, [selectedContrato]);

  const getContrato = useCallback(async id => {
    setLoading(true);

    try {
      const response = await api.get(`/contrato/${id}`);
      //console.log("response", response.data);

      setSelectedContrato(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getBanks = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`https://brasilapi.com.br/api/banks/v1`);
      //console.log("response", response.data);

      setBanks(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getDadosPagamento = useCallback(async hash => {
    setLoading(true);

    //console.log("getDadosPagamento", hash);

    try {
      const response = await api.get(`/contrato-dados-pagamento/${hash}`);
      //console.log("response Dados Pagamento", response.data);

      setDadosPagamento(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  function goBack() {
    history.push('/contratos/meus-contratos');
  }

  const handleSubmitPF = useCallback(
    async (data: DadosPagamentoFormData) => {
      if (!selectedBank.value) {
        addToast({
          type: 'error',
          title: 'Selecione o banco',
          description: '',
        });
        return;
      }

      if (!(data.celular || data.telefone)) {
        addToast({
          type: 'error',
          title: 'É obrigatório o cadastro de ao menos um telefone',
          description: '',
        });
        return;
      }

      try {
        //console.log("handleSubmitPF", data);

        setLoading(true);
        formRef_PF.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          cpf: Yup.string().required('CPF obrigatório'),
          // banco: Yup.string().required('Banco obrigatório'),
          agencia: Yup.string().required('Agência obrigatório'),
          conta: Yup.string().required('Conta obrigatório'),
          // telefone: Yup.string().required('Telefone obrigatório'),
          // celular: Yup.string().required('Celular obrigatório'),
          // digito_verificador: Yup.string().required('Digito verificador obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const banco = selectedBank.label.split(' - ');

        const params = {
          hash: selectedContrato.hash,
          modelo_pagamento: 'pf',
          cpf: data.cpf
            .split('_')
            .join('')
            .split('.')
            .join('')
            .split('-')
            .join(''),
          nome_completo: data.nome,
          email: selectedContrato.especialista?.email,
          telefone: data.telefone,
          celular: data.celular,
          numero_banco: selectedBank.value,
          banco: selectedBank.label.replace(`${banco[0]} - `, ''),
          agencia: data.agencia,
          conta: data.conta,
          digito_verificador: data.digito_verificador,
        };

        //console.log("params", params);

        const user_response = await Swal.fire({
          title: '<strong>Confirmar Dados</strong>',
          icon: 'warning',
          html: 'Verifique os dados com atenção antes de confirmar!',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: true,
          confirmButtonText: 'Confirmar',
          confirmButtonAriaLabel: 'Confirmar',
          cancelButtonText: 'Voltar',
          cancelButtonAriaLabel: 'Voltar',
          confirmButtonColor: '#1CAEBD',
          cancelButtonColor: '#312e38',
          reverseButtons: true,
        });

        if (!user_response.isConfirmed) {
          setLoading(false);
          return;
        }
        //console.log('params', params)
        await api.post(`/contrato-dados-pagamento`, params);

        // addToast({
        //   type: 'success',
        //   title: 'Dados cadastrados com sucesso',
        //   description: '',
        // });

        acceptContrato();
      } catch (err) {
        setLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef_PF.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar dados',
            description: err.response?.data[error]
              ? err.response.data[error]
              : 'Erro ao cadastrar dados, tente novamente mais tarde.',
          });
        }
      } finally {
        // setLoading(false);
      }
    },
    [selectedContrato, addToast, selectedBank],
  );

  const handleSubmitPJ = useCallback(
    async (data: DadosPagamentoFormData) => {
      if (showErrorCNAE) {
        addToast({
          type: 'error',
          title:
            'O CNPJ incluído não possui CNAEs aceitos pela Yduqs para contratação',
          description: 'Entre em contato no caso de dúvidas',
        });
        return;
      }

      if (!selectedBank.value) {
        addToast({
          type: 'error',
          title: 'Selecione o banco',
          description: '',
        });
        return;
      }

      if (!(data.celular || data.telefone)) {
        addToast({
          type: 'error',
          title: 'É obrigatório o cadastro de ao menos um telefone',
          description: '',
        });
        return;
      }

      try {
        // console.log("handleSubmitPJ", data);

        setLoading(true);
        formRef_PJ.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório'),
          cpf: Yup.string().required('CPF obrigatório'),
          // banco: Yup.string().required('Banco obrigatório'),
          agencia: Yup.string().required('Agência obrigatório'),
          conta: Yup.string().required('Conta obrigatório'),
          // telefone: Yup.string().required('Telefone obrigatório'),
          // celular: Yup.string().required('Celular obrigatório'),
          // digito_verificador: Yup.string().required('Digito verificador obrigatório'),
          cnpj: Yup.string()
            .required('CNPJ obrigatório')
            .test('len', 'Digite um CNPJ válido', val => cnpj.isValid(val)),
          razao_social: Yup.string().required('Razão Social é obrigatório'),
          empresa_cep: Yup.string().required('CEP da empresa é obrigatório'),
          empresa_rua: Yup.string().required('Rua da empresa é obrigatório'),
          empresa_numero: Yup.string().required(
            'Número da empresa é obrigatório',
          ),
          // empresa_complemento: Yup.string().required('Complemento da empresa é obrigatório'),
          empresa_cidade: Yup.string().required(
            'Cidade da empresa é obrigatório',
          ),
          empresa_bairro: Yup.string().required(
            'Bairro da empresa é obrigatório',
          ),
          empresa_estado: Yup.string().required('UF da empresa é obrigatório'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const banco = selectedBank.label.split(' - ');

        const params = {
          hash: selectedContrato.hash,
          modelo_pagamento: 'pj',
          cpf: data.cpf
            .split('_')
            .join('')
            .split('.')
            .join('')
            .split('-')
            .join(''),
          nome_completo: data.nome,
          email: selectedContrato.especialista?.email,
          telefone: data.telefone,
          celular: data.celular,
          numero_banco: selectedBank.value,
          banco: selectedBank.label.replace(`${banco[0]} - `, ''),
          agencia: data.agencia,
          conta: data.conta,
          digito_verificador: data.digito_verificador,
          pj_cnpj: data.cnpj
            ? data.cnpj
                .split('_')
                .join('')
                .split('.')
                .join('')
                .split('-')
                .join('')
                .split('/')
                .join('')
            : '',
          pj_razao_social: data.razao_social,
          pj_endereco_empresa: `${data.empresa_rua}, ${data.empresa_numero}${
            data.empresa_complemento ? ' - ' + data.empresa_complemento : ''
          }, ${data.empresa_bairro}, ${data.empresa_cep}, ${
            data.empresa_cidade
          } - ${data.empresa_estado}`,
        };

        // console.log("params", params);

        const user_response = await Swal.fire({
          title: '<strong>Confirmar Dados</strong>',
          icon: 'warning',
          html: 'Verifique os dados com atenção antes de confirmar!',
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: true,
          confirmButtonText: 'Confirmar',
          confirmButtonAriaLabel: 'Confirmar',
          cancelButtonText: 'Voltar',
          cancelButtonAriaLabel: 'Voltar',
          confirmButtonColor: '#1CAEBD',
          cancelButtonColor: '#312e38',
          reverseButtons: true,
        });

        if (!user_response.isConfirmed) {
          setLoading(false);
          return;
        }

        await api.post(`/contrato-dados-pagamento`, params);

        // addToast({
        //   type: 'success',
        //   title: 'Dados cadastrados com sucesso',
        //   description: '',
        // });

        acceptContrato();
      } catch (err) {
        setLoading(false);

        // console.log("error", err);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef_PJ.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar dados',
            description: err.response?.data[error]
              ? err.response.data[error]
              : 'Erro ao cadastrar dados, tente novamente mais tarde.',
          });
        }
      } finally {
        // setLoading(false);
      }
    },
    [selectedContrato, addToast, showErrorCNAE, selectedBank],
  );

  const updadeMatricula = useCallback(async () => {
    //console.log("updadeMatricula", matricula);

    if (matricula === selectedContrato.especialista?.dados_usuario?.matricula) {
      return;
    }

    if (matricula && matricula.length <= 6) {
      addToast({
        type: 'error',
        title: 'Matrícula inválida',
        description: 'Preencha uma matrícula válida',
      });
      return;
    }

    try {
      setLoading(true);

      const params: any = {
        dados_usuario: {
          matricula: matricula,
        },
      };

      //console.log("params", params);

      await api.put(`usuario/${selectedContrato.especialista.id}`, params);

      // addToast({
      //   type: 'success',
      //   title: 'Matrícula atualizada com sucesso',
      //   description: '',
      // });
    } catch (err) {
      setLoading(false);

      //console.log(err);

      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao atualizar matricula',
          description: err.response?.data[error]
            ? err.response.data[error]
            : 'Ocorreu um erro ao atualizar a matricula do usuário.',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [history, addToast, selectedContrato, showErrorCNAE, matricula]);

  const acceptContrato = useCallback(async () => {
    //console.log("acceptContrato");

    if (showFormInterno) {
      if (!matricula) {
        addToast({
          type: 'error',
          title: 'Preencha sua matrícula de colaborador Yduqs',
          description: '',
        });
        return;
      } else {
        if (matricula && matricula.length <= 6) {
          addToast({
            type: 'error',
            title: 'Matrícula inválida',
            description: 'Preencha uma matrícula válida',
          });
          return;
        }

        await updadeMatricula();
      }
    }

    try {
      setLoading(true);

      const params: any = {
        hash: selectedContrato.hash,
        aceito: true,
      };

      if (matricula) {
        params.matricula = matricula;
      }

      // console.log("params", params);

      const response = await api.post(`/aceite`, params);
      // console.log("response", response.data);

      const current_contrato = selectedContrato;

      current_contrato.arquivo_contrato = response.data.arquivo_contrato;
      current_contrato.arquivo_direito_imagem =
        response.data.arquivo_direito_imagem;

      setSelectedContrato(current_contrato);

      addToast({
        type: 'success',
        title: 'Parabéns! Contrato criado com sucesso',
        description:
          'Agora é só verificar se está tudo Ok no contrato e aceitar para começar a produção',
      });

      setLoading(false);
      setContratoConfirmado(true);
      setShowFormPF(false);
      setShowFormPJ(false);

      // if(selectedContrato.tipo_contratacao === "pessoa_juridica"){
      //   history.push('/contratos/upload', { selectedItem: selectedContrato, showMessage: true });
      // }else{

      // }

      // if(selectedContrato.tipo_contratacao === "pessoa_juridica"){
      //   addToast({
      //     type: 'success',
      //     title: "Parabéns! Contrato criado com sucesso",
      //     description: 'Agora é só verificar se está tudo Ok no contrato e aceitar para começar a produção',
      //   });

      //   setLoading(false);
      //   history.push('/contratos/upload', { selectedItem: selectedContrato, showMessage: true })
      // }else{
      //   setTimeout(() => {
      //     addToast({
      //       type: 'success',
      //       title: "Parabéns! Contrato criado com sucesso",
      //       description: 'Agora é só verificar se está tudo Ok no contrato e aceitar para começar a produção',
      //     });

      //     setLoading(false);
      //     goBack();

      //   }, 3000);
      // }
    } catch (err) {
      //console.log("error", err?.response?.data);

      addToast({
        type: 'error',
        title: 'Erro ao confirmar produção',
        description: 'Erro ao confirmar produção, tente novamente mais tarde.',
      });

      // for (let error in err.response?.data) {
      //   addToast({
      //     type: 'error',
      //     title: 'Erro ao confirmar produção',
      //     description: err.response?.data[error] ?
      //       err.response.data[error] :
      //       'Erro ao confirmar produção, tente novamente mais tarde.',
      //   });
      // }

      setLoading(false);
    }
  }, [selectedContrato, showFormInterno, matricula]);

  const acceptContratoReal = useCallback(async () => {
    //console.log("acceptContratoReal");

    if (contratoAceito === false) {
      addToast({
        type: 'error',
        title: 'É obrigatório ler e aceitar os termos do contrato',
        description: '',
      });

      return;
    }

    setLoading(true);

    try {
      const params = {
        aceito: true,
      };

      //console.log("params", params);

      const response = await api.put(
        `/aceite/${selectedContrato.hash}`,
        params,
      );
      //console.log("response", response.data);

      addToast({
        type: 'success',
        title: 'Parabéns! Contrato aceito com sucesso',
        description: '',
      });

      setLoading(false);

      if (selectedContrato.tipo_contratacao === 'pessoa_juridica') {
        history.push('/contratos/upload', {
          selectedItem: selectedContrato,
          showMessage: true,
        });
      } else {
        goBack();
      }
    } catch (err) {
      //console.log("error", err.response?.data);

      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao aceitar contrato',
          description: err.response?.data[error]
            ? err.response.data[error]
            : 'Erro ao aceitar contrato, tente novamente mais tarde.',
        });
      }

      setLoading(false);
    } finally {
      setLoading(false);
    }
  }, [selectedContrato, contratoAceito]);

  const denyContrato = useCallback(async () => {
    //console.log("denyContrato");

    const user_response = await Swal.fire({
      title: '<strong>Atenção!</strong>',
      icon: 'warning',
      html: 'Tem certeza que deseja recusar este convite?',
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: true,
      confirmButtonText: 'Recusar',
      confirmButtonAriaLabel: 'Recusar',
      cancelButtonText: 'Voltar',
      cancelButtonAriaLabel: 'Voltar',
      confirmButtonColor: '#333',
      cancelButtonColor: '#666',
      reverseButtons: true,
    });

    if (!user_response.isConfirmed) {
      return;
    }

    setLoading(true);

    try {
      const params = {
        hash: selectedContrato.hash,
        aceito: false,
      };

      //console.log("params", params);

      const response = await api.post(`/aceite`, params);
      //console.log("response", response.data);

      addToast({
        type: 'success',
        title: 'Contrato recusado com sucesso',
        description: '',
      });
      goBack();
    } catch (err) {
      //console.log("error", err.response?.data);

      for (let error in err.response?.data) {
        addToast({
          type: 'error',
          title: 'Erro ao cadastrar contrato',
          description: err.response?.data[error]
            ? err.response.data[error]
            : 'Erro ao cadastrar contrato, tente novamente mais tarde.',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [selectedContrato]);

  const formatUF = useCallback(async (uf, cnpj = false) => {
    if (cnpj) {
      formRef.current?.setFieldValue('empresa_estado', uf.toUpperCase());
    } else {
      formRef.current?.setFieldValue('estado', uf.toUpperCase());
    }
  }, []);

  const searchCNPJ = useCallback(async input_cnpj => {
    if (input_cnpj.length > 0) {
      const cnpj_final = input_cnpj
        .split('_')
        .join('')
        .replace('-', '')
        .split('.')
        .join('')
        .replace('/', '');
      //console.log("cnpj_final:", cnpj_final);

      if (!cnpj.isValid(cnpj_final)) {
        formRef_PJ.current?.setFieldError('cnpj', 'CNPJ inválido');
      } else {
        formRef_PJ.current?.setFieldError('cnpj', '');
      }

      if (cnpj_final.length === 14 && cnpj.isValid(cnpj_final)) {
        try {
          const response = await api.get(
            `https://brasilapi.com.br/api/cnpj/v1/${cnpj_final}`,
          );
          //console.log("cnpj:", response);

          if (response.data) {
            setCnpjNotFound(false);
            // const response_cep = await api.get(`https://brasilapi.com.br/api/cep/v1/${response.data.cep}`);
            //console.log("cnpj cep:", response_cep);

            formRef_PJ.current?.setFieldValue(
              'razao_social',
              response.data.razao_social,
            );
            formRef_PJ.current?.setFieldValue('empresa_cep', response.data.cep);
            formRef_PJ.current?.setFieldValue(
              'empresa_rua',
              response.data.logradouro,
            );
            formRef_PJ.current?.setFieldValue(
              'empresa_numero',
              response.data.numero,
            );
            formRef_PJ.current?.setFieldValue(
              'empresa_complemento',
              response.data.complemento,
            );
            formRef_PJ.current?.setFieldValue(
              'empresa_cidade',
              response.data.municipio,
            );
            formRef_PJ.current?.setFieldValue(
              'empresa_bairro',
              response.data.bairro,
            );
            formRef_PJ.current?.setFieldValue(
              'empresa_estado',
              response.data.uf,
            );

            checkCNAES(response.data);
          } else {
            setCnpjNotFound(true);
            setShowErrorCNAE(false);
          }
        } catch (error) {
          setCnpjNotFound(true);
          setShowErrorCNAE(false);
        }
      } else {
        formRef_PJ.current?.setFieldValue('razao_social', '');
        formRef_PJ.current?.setFieldValue('empresa_cep', '');
        formRef_PJ.current?.setFieldValue('empresa_rua', '');
        formRef_PJ.current?.setFieldValue('empresa_numero', '');
        formRef_PJ.current?.setFieldValue('empresa_complemento', '');
        formRef_PJ.current?.setFieldValue('empresa_cidade', '');
        formRef_PJ.current?.setFieldValue('empresa_bairro', '');
        formRef_PJ.current?.setFieldValue('empresa_estado', '');
      }
    } else {
      formRef_PJ.current?.setFieldValue('razao_social', '');
      formRef_PJ.current?.setFieldValue('empresa_cep', '');
      formRef_PJ.current?.setFieldValue('empresa_rua', '');
      formRef_PJ.current?.setFieldValue('empresa_numero', '');
      formRef_PJ.current?.setFieldValue('empresa_complemento', '');
      formRef_PJ.current?.setFieldValue('empresa_cidade', '');
      formRef_PJ.current?.setFieldValue('empresa_bairro', '');
      formRef_PJ.current?.setFieldValue('empresa_estado', '');

      setShowErrorCNAE(false);
    }
  }, []);

  function handleChangeSelectedBank(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedBank(newValue);
  }

  // const searchCNPJ = useCallback(async (cnpj) => {
  //   if (cnpj.length > 0) {
  //     const cnpj_final = cnpj.split("_").join("").replace("-", "").split(".").join("").replace("/", "");
  //     //console.log("cnpj_final:", cnpj_final);

  //     if (cnpj_final.length === 14) {
  //       try {
  //         const response = await api.get(`https://brasilapi.com.br/api/cnpj/v1/${cnpj_final}`);
  //         //console.log("cnpj:", response);

  //         if (response.data) {
  //           setCnpjNotFound(false);
  //           // const response_cep = await api.get(`https://brasilapi.com.br/api/cep/v1/${response.data.cep}`);
  //           //console.log("cnpj cep:", response_cep);

  //           formRef_PJ.current?.setFieldValue("razao_social", response.data.razao_social);
  //           formRef_PJ.current?.setFieldValue("empresa_cep", response.data.cep);
  //           formRef_PJ.current?.setFieldValue("empresa_rua", response.data.logradouro);
  //           formRef_PJ.current?.setFieldValue("empresa_numero", response.data.numero);
  //           formRef_PJ.current?.setFieldValue("empresa_complemento", response.data.complemento);
  //           formRef_PJ.current?.setFieldValue("empresa_cidade", response.data.municipio);
  //           formRef_PJ.current?.setFieldValue("empresa_bairro", response.data.bairro);
  //           formRef_PJ.current?.setFieldValue("empresa_estado", response.data.uf);

  //           checkCNAES(response.data);
  //         }else{
  //           setCnpjNotFound(true);
  //           setShowErrorCNAE(false);

  //           // formRef.current?.setFieldValue("razao_social", "");
  //           // formRef.current?.setFieldValue("empresa_cep", "");
  //           // formRef.current?.setFieldValue("empresa_rua", "");
  //           // formRef.current?.setFieldValue("empresa_numero", "");
  //           // formRef.current?.setFieldValue("empresa_complemento", "");
  //           // formRef.current?.setFieldValue("empresa_cidade", "");
  //           // formRef.current?.setFieldValue("empresa_bairro", "");
  //           // formRef.current?.setFieldValue("empresa_estado", "");
  //         }

  //       } catch (error) {
  //         setCnpjNotFound(true);
  //         setShowErrorCNAE(false);

  //         // formRef.current?.setFieldValue("razao_social", "");
  //         // formRef.current?.setFieldValue("empresa_cep", "");
  //         // formRef.current?.setFieldValue("empresa_rua", "");
  //         // formRef.current?.setFieldValue("empresa_numero", "");
  //         // formRef.current?.setFieldValue("empresa_complemento", "");
  //         // formRef.current?.setFieldValue("empresa_cidade", "");
  //         // formRef.current?.setFieldValue("empresa_bairro", "");
  //         // formRef.current?.setFieldValue("empresa_estado", "");
  //       }
  //     }
  //   }else{
  //     // formRef.current?.setFieldValue("razao_social", "");
  //     // formRef.current?.setFieldValue("empresa_cep", "");
  //     // formRef.current?.setFieldValue("empresa_rua", "");
  //     // formRef.current?.setFieldValue("empresa_numero", "");
  //     // formRef.current?.setFieldValue("empresa_complemento", "");
  //     // formRef.current?.setFieldValue("empresa_cidade", "");
  //     // formRef.current?.setFieldValue("empresa_bairro", "");
  //     // formRef.current?.setFieldValue("empresa_estado", "");

  //     setShowErrorCNAE(false);
  //   }
  // }, []);

  const checkCNAES = useCallback(
    async cnpj => {
      if (cnpj && isEspecialista) {
        const cnaes_permitidos = [
          8599604,
          5811500,
          6202333,
          8550302,
          9101500,
          8599699,
        ];
        let found = false;

        cnaes_permitidos.forEach(cnae_permitido => {
          if (cnae_permitido == cnpj.cnae_fiscal) {
            found = true;
          } else {
            if (cnpj.cnaes_secundarias) {
              cnpj.cnaes_secundarias.forEach((cnae_secundario: any) => {
                if (cnae_permitido == cnae_secundario.codigo) {
                  found = true;
                }
              });
            }
          }
        });

        //console.log("cnae_fiscal", cnpj.cnae_fiscal);
        //console.log("cnaes_secundarias", cnpj.cnaes_secundarias);

        if (!found) {
          setShowErrorCNAE(true);
        } else {
          setShowErrorCNAE(false);
        }
      } else {
        setShowErrorCNAE(false);
      }
    },
    [isEspecialista],
  );

  const searchCEP_PJ = useCallback(async cep => {
    if (cep.length > 0) {
      const cep_final = cep.split('_').join('').replace('-', '');

      if (cep_final.length === 8) {
        const response = await api.get(
          `https://brasilapi.com.br/api/cep/v1/${cep_final}`,
        );
        //console.log("cep:", response);

        if (response.data) {
          formRef_PJ.current?.setFieldValue(
            'empresa_rua',
            response.data.street,
          );
          formRef_PJ.current?.setFieldValue(
            'empresa_cidade',
            response.data.city,
          );
          formRef_PJ.current?.setFieldValue(
            'empresa_bairro',
            response.data.neighborhood,
          );
          formRef_PJ.current?.setFieldValue(
            'empresa_estado',
            response.data.state,
          );
        }
      }
    }
  }, []);

  return (
    <Container>
      <Content>
        <div className="form">
          <Card>
            <div className="header" style={{ height: '0px' }}>
              <h2>
                {(!showFormPF && !showFormPJ) || contratoConfirmado
                  ? 'Resumo do Contrato'
                  : showFormPF
                  ? 'Confirmação de Dados para Pagamento - Pessoa Física'
                  : showFormPJ
                  ? 'Confirmação de Dados para Pagamento - Pessoa Jurídica'
                  : 'Resumo do Contrato'}
              </h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            {((!showFormPF && !showFormPJ) || contratoConfirmado) && (
              <ContratoData>
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Dados do Contrato:</div>
                  <div className="wrapper-data-info">
                    Tipo de Contrato:{' '}
                    {selectedContrato.tipo_contrato === 'producao_atual'
                      ? 'Produção'
                      : selectedContrato.tipo_contrato === 'manutencao_reativa'
                      ? 'Manutenção Reativa'
                      : selectedContrato.tipo_contrato === 'manutencao_proativa'
                      ? 'Manutenção Proativa'
                      : selectedContrato.tipo_contrato ===
                        'manutencao_proativa_direcionada'
                      ? 'Manutenção Proativa Direcionada'
                      : selectedContrato.tipo_contrato === 'terceirizado'
                      ? 'Terceirizado'
                      : selectedContrato.tipo_contrato === 'prestacao_servico'
                      ? 'Prestação de Serviço'
                      : selectedContrato.tipo_contrato
                      ? selectedContrato.tipo_contrato
                          .replace('_', ' ')[0]
                          .toUpperCase() +
                        selectedContrato.tipo_contrato
                          .replace('_', ' ')
                          .substring(1)
                      : ''}
                  </div>
                  <div className="wrapper-data-info">
                    Tipo de Contratação:
                    {selectedContrato.tipo_contratacao === 'pessoa_juridica'
                      ? ' Pessoa Juridica'
                      : ''}
                    {selectedContrato.tipo_contratacao === 'pessoa_fisica'
                      ? ' Pessoa Física'
                      : ''}
                    {selectedContrato.tipo_contratacao === 'interno'
                      ? ' Interno'
                      : ''}
                  </div>
                  <div className="wrapper-data-info">
                    Data Contrato: {dataContrato}
                  </div>
                  {selectedContrato.data_prestacao_servico ? (
                    <>
                      <div className="wrapper-data-info">
                        Prazo de Entrega: {dataPrestacaoServico}
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="wrapper-data-info">
                        Prazo de Entrega: {dataPrazoEntrega}
                      </div>
                    </>
                  )}
                  {selectedContrato.cliente?.nome === 'Ensino' ||
                  selectedContrato.cliente?.nome === 'Premium' ? (
                    <div className="wrapper-data-info">
                      {selectedContrato.cliente?.nome === 'Ensino'
                        ? 'Informações importantes para o docente:'
                        : 'Observações:'}
                      <div
                        style={{ marginTop: '10px', marginLeft: '10px' }}
                        dangerouslySetInnerHTML={{
                          __html: selectedContrato.observacoes?.replace(
                            /\n/g,
                            '<br>',
                          ),
                        }}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {selectedContrato.tema ? (
                  <div className="wrapper-data">
                    <div className="wrapper-data-label">Tema:</div>
                    <div className="wrapper-data-info">
                      {selectedContrato.tema?.titulo}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {selectedContrato.disciplina_producao ? (
                  <div className="wrapper-data">
                    <div className="wrapper-data-label">Disciplina:</div>
                    <div className="wrapper-data-info">
                      {selectedContrato.disciplina_producao?.disciplina?.titulo}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                {selectedContrato.curso_producao ? (
                  <div className="wrapper-data">
                    <div className="wrapper-data-label">Curso:</div>
                    <div className="wrapper-data-info">
                      {selectedContrato.curso_producao?.curso?.titulo}
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="wrapper-data">
                  <div className="wrapper-data-label">Especialista:</div>
                  <div className="wrapper-data-info">
                    Nome: {selectedContrato.especialista?.dados_usuario?.nome}
                  </div>
                  <div className="wrapper-data-info">
                    Email: {selectedContrato.especialista?.email}
                  </div>
                </div>

                <div className="wrapper-data">
                  <div className="wrapper-data-label">Remetente:</div>
                  <div className="wrapper-data-info">
                    Nome: {selectedContrato.remetente?.dados_usuario?.nome}
                  </div>
                  <div className="wrapper-data-info">
                    Email: {selectedContrato.remetente?.email}
                  </div>
                </div>

                <div className="wrapper-data">
                  <div className="wrapper-data-label">Produtos:</div>

                  {selectedContrato.produtos?.map((item: any) => (
                    <div key={item.id} className="wrapper-produto">
                      <div className="wrapper-data-label">{item.titulo}:</div>
                      <div className="wrapper-data-info">
                        Quantidade: {item.pivot.quantidade}
                      </div>
                      {item.pivot.justificativa ? (
                        <>
                          {/* <div className="wrapper-data-info">
                            Valor: R$ {parseFloat(item.valor).toFixed(2)}
                          </div> */}
                          <div className="wrapper-data-info">
                            Valor: R$ {parseFloat(item.pivot.valor).toFixed(2)}
                          </div>

                          <div className="wrapper-data-info">
                            Valor total: R${' '}
                            {parseFloat(item.pivot.valor_total).toFixed(2)}
                          </div>

                          {/* <div className="wrapper-data-info">
                            Justificativa: {item.pivot.justificativa}
                          </div> */}
                        </>
                      ) : (
                        <>
                          <div className="wrapper-data-info">
                            Valor: R$ {parseFloat(item.valor).toFixed(2)}
                          </div>
                          <div className="wrapper-data-info">
                            Valor total: R${' '}
                            {parseFloat(item.pivot.valor_total).toFixed(2)}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                  {selectedContrato.valor_total >= 0 && (
                    <div className="wrapper-money">
                      <div
                        className="wrapper-data-label"
                        style={{
                          paddingTop: '25px',
                          fontSize: '30px',
                          color: '#999',
                        }}
                      >
                        R$ {parseFloat(selectedContrato.valor_total).toFixed(2)}
                      </div>
                    </div>
                  )}
                </div>

                {selectedContrato.arquivo_contrato && (
                  <div className="wrapper-data">
                    <div className="wrapper-data-label">Contratos:</div>
                    {selectedContrato.arquivo_contrato ? (
                      <div className="wrapper-data-info">
                        <a
                          className="link_upload"
                          href={`${selectedContrato.arquivo_contrato.url}?token=${token}`}
                          target="_blank"
                        >
                          <BsFileEarmarkText />
                          Contrato de Prestação de Serviços
                        </a>
                      </div>
                    ) : (
                      <div className="wrapper-data-info">
                        <span className="link_upload">
                          Contrato de Prestação de Serviços: Não gerado
                        </span>
                      </div>
                    )}
                    {selectedContrato.arquivo_direito_imagem && (
                      <div className="wrapper-data-info">
                        <a
                          className="link_upload"
                          href={`${selectedContrato.arquivo_direito_imagem.url}?token=${token}`}
                          target="_blank"
                        >
                          <BsFileRichtext />
                          Termo de Autorização de Direitos de Imagem, Nome e Voz
                        </a>
                      </div>
                    )}

                    <WrapRow style={{ marginTop: '30px' }}>
                      <Switch
                        uncheckedIcon={false}
                        checkedIcon={false}
                        offColor={'#e6e6e6'}
                        onColor={'#2FB4C2'}
                        onChange={() => {
                          setContratoAceito(!contratoAceito);
                        }}
                        checked={contratoAceito}
                      />
                      <span className="text-switch">
                        Li e aceito os termos do(s) contrato(s)
                      </span>
                    </WrapRow>
                  </div>
                )}

                {!showFormAceite && !contratoConfirmado && (
                  <WrapRow>
                    <TableButtonRefused
                      type="button"
                      onClick={denyContrato}
                      disabled={loading}
                      style={{ marginTop: '50px' }}
                    >
                      Recusar
                    </TableButtonRefused>

                    <WrapRowItem>
                      <Button
                        type="button"
                        loading={loading}
                        style={{ marginTop: '50px' }}
                        onClick={() => {
                          if (
                            selectedContrato.tipo_contratacao ===
                            'pessoa_fisica'
                          ) {
                            setShowFormPF(true);
                          } else if (
                            selectedContrato.tipo_contratacao ===
                            'pessoa_juridica'
                          ) {
                            setShowFormPJ(true);
                          }
                        }}
                      >
                        Confirmar Produção
                      </Button>
                    </WrapRowItem>
                  </WrapRow>
                )}
              </ContratoData>
            )}
          </Card>

          {showFormPF && (
            <Form ref={formRef_PF} onSubmit={handleSubmitPF}>
              <Card>
                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="nome"
                      type="text"
                      placeholder="Nome Completo"
                      disabled
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="email"
                      type="text"
                      placeholder="Email"
                      disabled
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInputMask
                      name="cpf"
                      type="text"
                      placeholder="CPF"
                      mask="999.999.999-99"
                      disabled
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="telefone"
                      type="text"
                      placeholder="Telefone"
                    />
                  </WrapRowItem>

                  <WrapRowItem>
                    <FormInput
                      name="celular"
                      type="text"
                      placeholder="Celular"
                    />
                  </WrapRowItem>
                </WrapRow>
              </Card>

              <Card>
                <h2>Dados Bancários Pessoa Física</h2>

                <WrapRow style={{ marginTop: '30px' }}>
                  <WrapRowItem>
                    <label>Banco:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedBank}
                      options={optionsBank}
                      value={selectedBank}
                    />
                  </WrapRowItem>
                </WrapRow>

                {/* <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="banco"
                      type="text"
                      placeholder="Banco"
                    />
                  </WrapRowItem>
                </WrapRow> */}

                <WrapRow style={{ marginTop: '30px' }}>
                  <WrapRowItem>
                    <FormInput
                      name="agencia"
                      type="text"
                      placeholder="Agência"
                    />
                  </WrapRowItem>

                  <WrapRowItem>
                    <FormInput
                      name="conta"
                      type="text"
                      placeholder="Conta Corrente"
                    />
                  </WrapRowItem>

                  <WrapRowItem>
                    <FormInput
                      name="digito_verificador"
                      type="text"
                      placeholder="Dígito verificador"
                      // mask="9"
                    />
                  </WrapRowItem>
                </WrapRow>
              </Card>

              <Button type="submit" loading={loading}>
                Confirmar Dados
              </Button>
            </Form>
          )}

          {showFormPJ && (
            <Form ref={formRef_PJ} onSubmit={handleSubmitPJ}>
              <Card>
                <h2>Dados Pessoais</h2>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="nome"
                      type="text"
                      placeholder="Nome Completo"
                      disabled
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="email"
                      type="text"
                      placeholder="Email"
                      disabled
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInputMask
                      name="cpf"
                      type="text"
                      placeholder="CPF"
                      mask="999.999.999-99"
                      disabled
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="telefone"
                      type="text"
                      placeholder="Telefone"
                    />
                  </WrapRowItem>

                  <WrapRowItem>
                    <FormInput
                      name="celular"
                      type="text"
                      placeholder="Celular"
                    />
                  </WrapRowItem>
                </WrapRow>
              </Card>

              <Card>
                <h2>Pessoa Jurídica</h2>

                <WrapRow>
                  <WrapRowItem>
                    <FormInputMask
                      name="cnpj"
                      type="text"
                      placeholder="CNPJ"
                      mask="99.999.999/9999-99"
                      onChange={evt => searchCNPJ(evt.target.value)}
                    />
                  </WrapRowItem>
                </WrapRow>

                {showErrorCNAE && (
                  <WrapRow>
                    <WrapRowItem>
                      <div style={{ color: '#900', marginBottom: '25px' }}>
                        * O CNPJ inserido não possui CNAEs permitido pela YDUQS.
                        Entre em contato no caso de dúvidas.
                      </div>
                    </WrapRowItem>
                  </WrapRow>
                )}

                <WrapRow>
                  <WrapRowItem>
                    <div style={{ color: '#900', marginBottom: '25px' }}>
                      Lista de CNAEs aceitos: <br />
                      <br />
                      85.99-6-04: Treinamento Em Desenvolvimento Profissional E
                      Gerencial <br />
                      58.11-5-00: Edição De Livros <br />
                      62.02-3-33: Desenvolvimento E Licenciamento De Programas
                      De Computador Customizáveis <br />
                      85.50-3-02: Atividades De Apoio À Educação, Exceto Caixas
                      Escolares <br />
                      91.01-5-00: Atividades De Biblioteca E Arquivos <br />
                      85.99-6-99: Outras Atividades De Ensino Não Especificadas
                      Anteriormente
                    </div>
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="razao_social"
                      type="text"
                      placeholder="Razão Social"
                      // disabled={!cnpjNotFound}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInputMask
                      name="empresa_cep"
                      type="text"
                      placeholder="CEP"
                      mask="99999-999"
                      onChange={evt => searchCEP_PJ(evt.target.value)}
                      // disabled={!cnpjNotFound}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="empresa_rua"
                      type="text"
                      placeholder="Rua"
                      // disabled={!cnpjNotFound}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="empresa_numero"
                      type="text"
                      placeholder="Número"
                      // disabled={!cnpjNotFound}
                    />
                  </WrapRowItem>

                  <WrapRowItem>
                    <FormInput
                      name="empresa_complemento"
                      type="text"
                      placeholder="Complemento"
                      // disabled={!cnpjNotFound}
                    />
                  </WrapRowItem>

                  <WrapRowItem>
                    <FormInput
                      name="empresa_bairro"
                      type="text"
                      placeholder="Bairro"
                      // disabled={!cnpjNotFound}
                    />
                  </WrapRowItem>
                </WrapRow>

                <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="empresa_cidade"
                      type="text"
                      placeholder="Cidade"
                      // disabled={!cnpjNotFound}
                    />
                  </WrapRowItem>

                  <WrapRowItem>
                    <FormInputMask
                      name="empresa_estado"
                      type="text"
                      placeholder="Estado"
                      mask="**"
                      onChange={evt => formatUF(evt.target.value, true)}
                      // disabled={!cnpjNotFound}
                    />
                  </WrapRowItem>
                </WrapRow>
              </Card>

              <Card>
                <h2>Dados Bancários Pessoa Jurídica</h2>

                <WrapRow style={{ marginTop: '30px' }}>
                  <WrapRowItem>
                    <label>Banco:</label>
                    <Select
                      styles={{
                        // Fixes the overlapping problem of the component
                        menu: provided => ({ ...provided, zIndex: 9999 }),
                        // input: styles => ({ ...styles }),
                      }}
                      placeholder={''}
                      onChange={handleChangeSelectedBank}
                      options={optionsBank}
                      value={selectedBank}
                    />
                  </WrapRowItem>
                </WrapRow>

                {/* <WrapRow>
                  <WrapRowItem>
                    <FormInput
                      name="banco"
                      type="text"
                      placeholder="Banco"
                    />
                  </WrapRowItem>
                </WrapRow> */}

                <WrapRow style={{ marginTop: '30px' }}>
                  <WrapRowItem>
                    <FormInput
                      name="agencia"
                      type="text"
                      placeholder="Agência"
                    />
                  </WrapRowItem>

                  <WrapRowItem>
                    <FormInput
                      name="conta"
                      type="text"
                      placeholder="Conta Corrente"
                    />
                  </WrapRowItem>

                  <WrapRowItem>
                    <FormInput
                      name="digito_verificador"
                      type="text"
                      placeholder="Dígito verificador"
                      // mask="9"
                    />
                  </WrapRowItem>
                </WrapRow>
              </Card>

              <Button type="submit" loading={loading}>
                Confirmar Dados
              </Button>
            </Form>
          )}

          {showFormAceite && !contratoConfirmado && (
            <Form onSubmit={() => {}}>
              {showFormInterno && (
                <Card>
                  <h2>Dados Colaborador Interno</h2>
                  <WrapRow>
                    <WrapRowItem>
                      <FormInput
                        name="matricula"
                        type="number"
                        placeholder="Matrícula Colaborador Yduqs"
                        value={matricula}
                        onChange={evt => setMatricula(evt.target.value)}
                      />
                    </WrapRowItem>
                  </WrapRow>
                </Card>
              )}

              <WrapRow>
                <TableButtonRefused
                  type="button"
                  onClick={denyContrato}
                  disabled={loading}
                  style={{ marginTop: '10px', marginRight: '5px' }}
                >
                  Recusar
                </TableButtonRefused>

                <TableButtonAccepted
                  type="button"
                  onClick={acceptContrato}
                  disabled={loading}
                  style={{ marginTop: '10px' }}
                >
                  Confirmar Produção
                </TableButtonAccepted>
              </WrapRow>
            </Form>
          )}
          {/* (selectedContrato.tipo_contratacao === "pessoa_fisica") */}
          {((!(showFormAceite && !contratoConfirmado) &&
            selectedContrato.tipo_contratacao === 'interno') ||
            (selectedContrato.tipo_contratacao === 'pessoa_fisica' &&
              contratoConfirmado &&
              !showFormAceite) ||
            (selectedContrato.tipo_contratacao === 'pessoa_juridica' &&
              contratoConfirmado &&
              !showFormAceite)) && (
            <Form onSubmit={() => {}}>
              <WrapRow>
                <TableButtonRefused
                  type="button"
                  onClick={denyContrato}
                  disabled={loading}
                  style={{ marginTop: '50px', marginRight: '5px' }}
                >
                  Recusar
                </TableButtonRefused>

                <TableButtonAccepted
                  type="button"
                  onClick={acceptContratoReal}
                  disabled={loading}
                  style={{ marginTop: '50px' }}
                >
                  Aceitar Contrato
                </TableButtonAccepted>
              </WrapRow>
            </Form>
          )}
        </div>
      </Content>
    </Container>
  );
};

export default AceitarContratos;
