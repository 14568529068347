import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiX } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';

import convertUTCDateToLocalDate from '../../utils/convertUTCDateToLocalDate';
import getValidationErrors from '../../utils/getValidationErrors';

import { cnpj, cpf } from 'cpf-cnpj-validator';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
// import Loading from '../../components/Loading';
import Button from '../../components/Button';
import FormInput from '../../components/FormInput';
// import FormInputMask from '../../components/FormInputMask';
import FormInputMaskSimple from '../../components/FormInputMaskSimple';
import Timeline from '../../components/Timeline';
// import UploadInput from '../../components/UploadInput';

import api from '../../services/api';

import {
  ButtonStatus,
  Container,
  Content,
  ContratoData,
  WrapRow,
  WrapRowItem,
} from './styles';

interface ContratosVisualizarInterface {
  id: string;
  data_contrato: string;
  prazo_entrega: string;
  tema: any;
  projeto: any;
  periodo: any;
  observacoes: string;
  produtos: any;
  especialista: any;
  remetente: any;
  valor_total: any;
  hash: string;
  tipo_contratacao: string;
  contatos: any;
  data_pagamento_comandado: string;
  data_pagamento_permitido: string;
  data_pagamento_previsao: string;
  status: any;
  dados_pagamento: any;
  arquivos: any;
  timeline: any;
  arquivo_contrato: any;
  arquivo_direito_imagem: any;
  status_cancelamento: any;
  blacklist: any;
  matricula: string;
  disciplina_producao: any;
  curso_producao: any;
  id_tema: string;
  id_projeto: string;
  tipo_contrato: string;
  data_prestacao_servico: string;
  arquivo_certificado: any;
  cliente: any;
}

interface OptionsData {
  value: any;
  label: string;
}

const ContratosVisualizar: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const history = useHistory();
  const location: any = useLocation();

  const { user, token, roles, permissions, routes } = useAuth();
  const { addToast } = useToast();

  const MySwal = withReactContent(Swal);

  // let { hash } = useParams();
  //console.log("hash", hash)

  const [loading, setLoading] = useState(false);
  const [selectedContrato, setSelectedContrato] = useState<
    ContratosVisualizarInterface
  >({} as ContratosVisualizarInterface);
  const [pj, setPJ] = useState<any>({} as any);
  const [arquivos, setArquivos] = useState<any[]>([]);
  const [optionsStatus, setOptionsStatus] = useState<OptionsData[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [optionsStatusCancelamento, setOptionsStatusCancelamento] = useState<
    OptionsData[]
  >([]);
  const [selectedStatusCancelamento, setSelectedStatusCancelamento] = useState<
    OptionsData
  >({} as OptionsData);
  const [showStatusForm, setShowStatusForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState<
    ContratosVisualizarInterface
  >({} as ContratosVisualizarInterface);
  const [
    comandarPagamentosPermission,
    setComandarPagamentosPermission,
  ] = useState(false);
  const [
    permitirPagamentosPermission,
    setPermitirPagamentosPermission,
  ] = useState(false);
  const [
    cancelarPagamentosPermission,
    setCancelarPagamentosPermission,
  ] = useState(false);
  const [showCadastrarBlacklist, setShowCadastrarBlacklist] = useState(false);
  const [statusBlacklist, setStatusBlacklist] = useState<any[]>([]);
  const [optionsStatusBlacklist, setOptionsStatusBlacklist] = useState<
    OptionsData[]
  >([]);
  const [selectedStatusBlacklist, setSelectedStatusBlacklist] = useState<
    OptionsData
  >({ value: '', label: '' });
  const [forceGoBack, setForceGoBack] = useState(false);

  useEffect(() => {
    if (location && location.state) {
      //console.log("location", location);
      setSelectedItem(location.state.selectedItem);
    } else {
      if (location && location.search) {
        //console.log("location", location.search);
        const query = new URLSearchParams(location.search);
        const id_contrato = query.get('id_contrato');
        //console.log("id", id_contrato);

        if (id_contrato) {
          getContrato(id_contrato);
          setForceGoBack(true);
        }
      }
    }
  }, [location]);

  useEffect(() => {
    if (selectedItem && selectedItem.id) {
      getContrato(selectedItem.id);
    }
  }, [selectedItem]);

  useEffect(() => {
    if (selectedContrato && selectedContrato.id) {
      // console.log("contrato", selectedContrato)

      if (selectedContrato.especialista?.dados_usuario?.pj_cnpj) {
        searchCNPJ(selectedContrato.especialista?.dados_usuario?.pj_cnpj);
      }

      getStatusContrato();
      getStatusCancelamento();
      getStatusBlacklist();

      if (
        !selectedContrato.arquivo_certificado &&
        selectedContrato.cliente?.nome !== 'Premium'
      ) {
        if (
          selectedContrato.status?.slug === 'pagamento_permitido' ||
          selectedContrato.status?.slug === 'pagamento_comandado'
        ) {
          gerarCertificado();
        }
      }
    }
  }, [selectedContrato]);

  useEffect(() => {
    if (routes) {
      let temp = routes.find(
        (item: any) => item.path === '/contratos/comandar-pagamentos',
      );

      if (temp) {
        roles.forEach((item: any) => {
          if (temp.roles.indexOf(item) > -1) {
            setComandarPagamentosPermission(true);
          }
        });
      } else {
        setComandarPagamentosPermission(false);
      }

      let temp_ = routes.find(
        (item: any) => item.path === '/contratos/permitir-pagamentos',
      );

      if (temp_) {
        roles.forEach((item: any) => {
          if (temp_.roles.indexOf(item) > -1) {
            setPermitirPagamentosPermission(true);
          }
        });
      } else {
        setPermitirPagamentosPermission(false);
      }

      roles.forEach((item: any) => {
        if (
          item === 'curador' ||
          item === 'curador-adjunto' ||
          item === 'administrador'
        ) {
          setCancelarPagamentosPermission(true);
        }
      });
    }
  }, [routes, roles]);

  useEffect(() => {
    if (statusBlacklist.length === 0) return;

    var options: OptionsData[] = [];

    statusBlacklist.map(item => {
      options.push({ value: item.id, label: item.descricao });
    });

    setOptionsStatusBlacklist(options);
    //console.log("options statusBlacklist", options);
  }, [statusBlacklist]);

  const getContrato = useCallback(async id => {
    setLoading(true);

    try {
      const response = await api.get(`/contrato/${id}`);
      //console.log("response", response.data);

      setSelectedContrato(response.data);

      if (response.data.arquivos) {
        getTiposArquivo(response.data.arquivos);
      }
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getStatusContrato = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/status-contrato`);
      //console.log("status-contrato", response.data);

      const temp: OptionsData[] = [];

      response.data.forEach((item: any) => {
        if (
          selectedContrato.status?.slug === 'aceite' ||
          selectedContrato.status?.slug === 'erro_de_permissao'
        ) {
          if (
            item.slug === 'pagamento_permitido' ||
            item.slug === 'cancelado'
          ) {
            if (
              (item.slug === 'pagamento_comandado' &&
                permissions.indexOf('comandar-pagamento') > -1) ||
              item.slug !== 'pagamento_comandado' ||
              comandarPagamentosPermission
            ) {
              if (
                (item.slug === 'pagamento_permitido' &&
                  permissions.indexOf('permitir-pagamento') > -1) ||
                item.slug !== 'pagamento_permitido' ||
                permitirPagamentosPermission
              ) {
                temp.push({ value: item.id, label: item.descricao });
              }
            }
          }
        }
        if (selectedContrato.status?.slug === 'pagamento_permitido') {
          if (
            item.slug === 'pagamento_comandado' ||
            item.slug === 'erro_de_permissao' ||
            item.slug === 'cancelado'
          ) {
            if (
              (item.slug === 'pagamento_comandado' &&
                permissions.indexOf('comandar-pagamento') > -1) ||
              item.slug !== 'pagamento_comandado' ||
              comandarPagamentosPermission
            ) {
              if (
                (item.slug === 'pagamento_permitido' &&
                  permissions.indexOf('permitir-pagamento') > -1) ||
                item.slug !== 'pagamento_permitido' ||
                permitirPagamentosPermission
              ) {
                temp.push({ value: item.id, label: item.descricao });
              }
            }
          }
        }
        if (
          (selectedContrato.status?.slug === 'criado' ||
            selectedContrato.status?.slug === 'aguardando_aprovacao') &&
          (cancelarPagamentosPermission ||
            permissions.indexOf('cancelar-contrato') > -1)
        ) {
          // if (selectedContrato.status?.slug === "criado" && (cancelarPagamentosPermission || (permissions.indexOf("cancelar-contrato") > -1))) {
          if (item.slug === 'cancelado') {
            temp.push({ value: item.id, label: item.descricao });
          }
        }
      });

      setOptionsStatus(temp);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedContrato, cancelarPagamentosPermission, permissions]);

  const getStatusBlacklist = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/status-blacklist`);
      //console.log("getStatusBlacklist", response.data);

      setStatusBlacklist(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const gerarCertificado = useCallback(
    async (user_action = false) => {
      setLoading(true);

      const params: any = {
        hash: selectedContrato.hash,
      };

      try {
        const response = await api.post(`/certificado`, params);
        //console.log("gerarCertificado", response.data);

        if (user_action) {
          addToast({
            type: 'success',
            title: 'Certificado gerado com sucesso',
            description: '',
          });
        }

        getContrato(selectedContrato.id);
      } catch (error) {
        //console.log(error);

        if (user_action) {
          addToast({
            type: 'error',
            title: 'Houve um problema ao gerar o certificado',
            description: '',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [selectedContrato],
  );

  const getStatusCancelamento = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/status-cancelamento`);
      //console.log("status-cancelamento", response.data);

      const temp: OptionsData[] = [];

      response.data.forEach((item: any) => {
        temp.push({ value: item.id, label: item.descricao });
      });

      setOptionsStatusCancelamento(temp);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const getTiposArquivo = useCallback(async arquivosContrato => {
    setLoading(true);

    try {
      const response = await api.get(`/tipo-arquivo`);
      //console.log("response", response.data);

      let temp: any = [];

      arquivosContrato.forEach((item: any) => {
        let current = response.data.find(
          (n: any) => n.id === item.id_tipo_arquivo,
        );

        const params = {
          ...item,
          titulo: current ? current.titulo : '',
        };

        temp.push(params);
      });

      //console.log("arquivos", temp);
      setArquivos(temp);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: any) => {
      try {
        //console.log("handleSubmit", data);

        if (!selectedStatusBlacklist.value) {
          addToast({
            type: 'error',
            title: 'Selecione o motivo da redlist',
            description: '',
          });
          return;
        }

        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          descricao: Yup.string().required('Descrição obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const params = {
          blacklist: [
            {
              id_usuario: selectedContrato.especialista.id,
              id_contrato: selectedContrato.id,
              id_status_blacklist: selectedStatusBlacklist.value,
              descricao: data.descricao,
            },
          ],
        };

        //console.log("params", params);

        await api.put(`/usuario/${selectedContrato.especialista.id}`, params);

        addToast({
          type: 'success',
          title: 'Redlist cadastrada com sucesso',
          description: '',
        });

        setShowCadastrarBlacklist(false);
        getContrato(selectedContrato.id);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          return;
        }

        for (let error in err.response?.data) {
          addToast({
            type: 'error',
            title: 'Erro ao cadastrar redlist',
            description: err.response?.data[error]
              ? err.response.data[error]
              : 'Erro ao cadastrar redlist, tente novamente mais tarde.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [history, addToast, selectedStatusBlacklist, selectedContrato],
  );

  const dataContrato = useMemo(() => {
    if (selectedContrato.data_contrato) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_contrato),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPagamentoComandado = useMemo(() => {
    if (selectedContrato.data_pagamento_comandado) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_pagamento_comandado),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPagamentoPrevisao = useMemo(() => {
    if (selectedContrato.data_pagamento_previsao) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_pagamento_previsao),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPagamentoPermitido = useMemo(() => {
    if (selectedContrato.data_pagamento_permitido) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_pagamento_permitido),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPrazoEntrega = useMemo(() => {
    if (selectedContrato.prazo_entrega) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.prazo_entrega),
      );
      return `${('0' + dt.getDate()).slice(-2)}/${(
        '0' +
        (dt.getMonth() + 1)
      ).slice(-2)}/${dt.getFullYear()}`;
    } else {
      return '';
    }
  }, [selectedContrato]);

  const dataPrestacaoServico = useMemo(() => {
    if (selectedContrato.data_prestacao_servico) {
      let dt = convertUTCDateToLocalDate(
        new Date(selectedContrato.data_prestacao_servico),
      );

      const hora_split = selectedContrato.data_prestacao_servico
        .split('T')[1]
        .split(':');
      const hora = `${hora_split[0]}:${hora_split[1]}`;

      // console.log("hora", hora )

      if (hora !== '00:00') {
        return `${('0' + dt.getDate()).slice(-2)}/${(
          '0' +
          (dt.getMonth() + 1)
        ).slice(-2)}/${dt.getFullYear()} às ${hora}`;
      } else {
        return `${('0' + dt.getDate()).slice(-2)}/${(
          '0' +
          (dt.getMonth() + 1)
        ).slice(-2)}/${dt.getFullYear()}}`;
      }
    }
  }, [selectedContrato]);

  const searchCNPJ = useCallback(async cnpj => {
    if (cnpj.length > 0) {
      const cnpj_final = cnpj
        .split('_')
        .join('')
        .replace('-', '')
        .split('.')
        .join('')
        .replace('/', '');

      if (cnpj_final.length === 14) {
        const response = await api.get(
          `https://brasilapi.com.br/api/cnpj/v1/${cnpj_final}`,
        );
        // console.log("cnpj:", response.data);

        if (response.data) {
          setPJ(response.data);
        }
      }
    }
  }, []);

  function goBack() {
    if (forceGoBack) {
      history.push('/contratos');
    } else {
      history.goBack();
    }
  }

  function handleChangeSelectedStatus(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedStatus(newValue);
  }

  function handleChangeSelectedStatusCancelamento(
    newValue: any,
    actionMeta: any,
  ) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedStatusCancelamento(newValue);
  }

  const changeStatus = useCallback(
    async (data: any) => {
      setLoading(true);

      if (!selectedStatus.value) {
        addToast({
          type: 'error',
          title: 'Selecione o status',
          description: '',
        });
        return;
      }

      // if (selectedStatus.label === "pagamento permitido") {
      //   if (!(data.data_status)) {
      //     addToast({
      //       type: 'error',
      //       title: 'Selecione a data de permissão do pagamento',
      //       description: ''
      //     });
      //     return;
      //   }
      // }

      if (selectedStatus.label === 'pagamento comandado') {
        if (!data.data_status) {
          addToast({
            type: 'error',
            title: 'Selecione a data do pagamento comandado',
            description: '',
          });
          return;
        }
      }

      if (selectedStatus.label === 'cancelado') {
        if (!selectedStatusCancelamento.value) {
          addToast({
            type: 'error',
            title: 'Selecione o motivo de cancelamento',
            description: '',
          });
          return;
        }
      }

      try {
        const params: any = {
          id_status: selectedStatus.value,
        };

        if (selectedStatus.label === 'pagamento permitido') {
          params.hashs = [selectedContrato.hash];
          //console.log("params", params);

          await api.post(`/contrato-mudanca-status`, params);
        } else if (selectedStatus.label === 'pagamento comandado') {
          params.hashs = [selectedContrato.hash];

          let dt = data.data_status.split('/');

          const today = new Date();

          if (parseInt(dt[1]) > 12 || parseInt(dt[0]) > 31) {
            addToast({
              type: 'error',
              title: 'Data de previsão de pagamento inválida',
              description: '',
            });
            setLoading(false);
            return;
          }

          if (dt[2].replace('_', '').length !== 4) {
            addToast({
              type: 'error',
              title: 'Data de previsão de pagamento inválida',
              description: '',
            });
            setLoading(false);
            return;
          }

          if (parseInt(dt[2]) < today.getFullYear()) {
            addToast({
              type: 'error',
              title: 'Data de previsão de pagamento inválida',
              description: '',
            });
            setLoading(false);
            return;
          }

          if (new Date(`${dt[2]}-${dt[1]}-${dt[0]}`) < today) {
            addToast({
              type: 'error',
              title: 'Data de previsão de pagamento inválida',
              description: '',
            });
            setLoading(false);
            return;
          }

          params.data_pagamento_previsao = `${dt[2]}-${dt[1]}-${dt[0]}`;

          //console.log("params", params);

          await api.post(`/contrato-mudanca-status`, params);
        } else {
          params.id_status = selectedStatus.value;

          if (selectedStatus.label === 'cancelado') {
            params.id_status_cancelamento = selectedStatusCancelamento.value;
          }

          const id_curadoria = selectedContrato.tema
            ? selectedContrato.tema?.id_curadoria
            : selectedContrato.disciplina_producao
            ? selectedContrato.disciplina_producao?.id_curadoria
            : selectedContrato.curso_producao
            ? selectedContrato.curso_producao?.id_curadoria
            : '';

          if (id_curadoria) {
            params.id_curadoria = id_curadoria;
          }

          // console.log("params", params);

          const response = await api.put(
            `/contrato/${selectedContrato.id}`,
            params,
          );
          //console.log("response", response.data);
        }

        getContrato(selectedContrato.id);

        addToast({
          type: 'success',
          title: 'Status alterado com sucesso',
          description: '',
        });

        setShowStatusForm(false);
        setSelectedStatus({} as OptionsData);
        setSelectedStatusCancelamento({} as OptionsData);
      } catch (error) {
        //console.log(error);
      } finally {
        setLoading(false);
      }
    },
    [selectedContrato, selectedStatus, selectedStatusCancelamento],
  );

  function handleChangeSelectedStatusBlacklist(newValue: any, actionMeta: any) {
    if (!newValue) {
      setSelectedStatusBlacklist({ value: '', label: '' });
      return;
    }

    if (newValue.length === 0) {
      setSelectedStatusBlacklist({ value: '', label: '' });
      return;
    }

    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue StatusBlacklist", newValue.value);

    setSelectedStatusBlacklist(newValue);
  }
  //console.log('contrato', selectedContrato);
  //console.log('periodo', .tema.periodo);

  return (
    <Container>
      <Content>
        <div className="form">
          <Card>
            <div className="header" style={{ height: '0px' }}>
              <h2>Contrato - {selectedContrato.hash}</h2>
              <FiX onClick={() => goBack()} className="icon" />
            </div>

            <ContratoData>
              <div className="wrapper-data">
                <div className="wrapper-data-label">Tipo de Contrato:</div>
                <div className="wrapper-data-info">
                  {selectedContrato.tipo_contrato === 'producao_atual'
                    ? 'Produção'
                    : selectedContrato.tipo_contrato === 'manutencao_reativa'
                    ? 'Manutenção Reativa'
                    : selectedContrato.tipo_contrato === 'manutencao_proativa'
                    ? 'Manutenção Proativa'
                    : selectedContrato.tipo_contrato === 'manutencao_reativa'
                    ? 'Manutenção Reativa'
                    : selectedContrato.tipo_contrato === 'manutencao_proativa'
                    ? 'Manutenção Proativa'
                    : selectedContrato.tipo_contrato ===
                      'manutencao_proativa_direcionada'
                    ? 'Manutenção Proativa Direcionada'
                    : selectedContrato.tipo_contrato === 'terceirizado'
                    ? 'Terceirizado'
                    : selectedContrato.tipo_contrato === 'prestacao_servico'
                    ? 'Prestação de Serviço'
                    : selectedContrato.tipo_contrato
                    ? selectedContrato.tipo_contrato
                        .replace('_', ' ')[0]
                        .toUpperCase() +
                      selectedContrato.tipo_contrato
                        .replace('_', ' ')
                        .substring(1)
                    : ''}
                </div>
              </div>

              {selectedContrato && selectedContrato.id_tema ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Projeto:</div>
                  <div className="wrapper-data-info">
                    {selectedContrato.tema?.projeto.titulo}
                  </div>
                </div>
              ) : (
                <></>
              )}
              {selectedContrato &&
              selectedContrato.tema &&
              selectedContrato.tema.periodo ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Período:</div>
                  <div className="wrapper-data-info">
                    {selectedContrato.tema.periodo.periodo}
                  </div>
                </div>
              ) : (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Período:</div>
                  <div className="wrapper-data-info">
                    Período não disponível
                  </div>
                </div>
              )}

              {selectedContrato && selectedContrato.id_tema ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Tema:</div>
                  <div className="wrapper-data-info">
                    {selectedContrato.tema?.codigo_tema
                      ? `${selectedContrato.tema?.codigo_tema} - `
                      : ''}
                    {selectedContrato.tema?.titulo}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {selectedContrato && selectedContrato.disciplina_producao ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Disciplina:</div>
                  <div className="wrapper-data-info">
                    {selectedContrato.disciplina_producao?.codigo_producao
                      ? `${selectedContrato.disciplina_producao?.codigo_producao} - `
                      : ''}
                    {selectedContrato.disciplina_producao?.disciplina?.titulo}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {selectedContrato && selectedContrato.curso_producao ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Curso:</div>
                  <div className="wrapper-data-info">
                    {selectedContrato.curso_producao?.codigo_producao
                      ? `${selectedContrato.curso_producao?.codigo_producao} - `
                      : ''}
                    {selectedContrato.curso_producao?.curso?.titulo}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {selectedContrato.cliente?.tem_curadoria ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Curadoria:</div>
                  <div className="wrapper-data-info">
                    {selectedContrato.tema
                      ? selectedContrato.tema?.curadoria?.nome
                      : selectedContrato.disciplina_producao
                      ? selectedContrato.disciplina_producao?.curadoria?.nome
                      : selectedContrato.curso_producao?.curadoria?.nome}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {selectedContrato.cliente?.tem_area ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Área de Atuação:</div>
                  <div className="wrapper-data-info">
                    {selectedContrato.tema
                      ? selectedContrato.tema?.area_atuacao?.nome
                      : selectedContrato.disciplina_producao
                      ? selectedContrato.disciplina_producao?.area_atuacao?.nome
                      : selectedContrato.curso_producao?.area_atuacao?.nome}
                  </div>
                </div>
              ) : (
                <></>
              )}

              <div className="wrapper-data">
                <WrapRow>
                  <WrapRowItem>
                    <div className="wrapper-data-label">Status:</div>
                  </WrapRowItem>

                  {(selectedContrato.status?.slug === 'aceite' ||
                    selectedContrato.status?.slug === 'pagamento_permitido' ||
                    selectedContrato.status?.slug === 'erro_de_permissao' ||
                    ((selectedContrato.status?.slug === 'criado' ||
                      selectedContrato.status?.slug === 'aceite') &&
                      (cancelarPagamentosPermission ||
                        permissions.indexOf('cancelar-contrato') > -1))) && (
                    <>
                      {(permissions.indexOf('comandar-pagamento') > -1 ||
                        comandarPagamentosPermission ||
                        permissions.indexOf('permitir-pagamento') > -1 ||
                        permitirPagamentosPermission ||
                        ((selectedContrato.status?.slug === 'criado' ||
                          selectedContrato.status?.slug === 'aceite') &&
                          (cancelarPagamentosPermission ||
                            permissions.indexOf('cancelar-contrato') >
                              -1))) && (
                        <div
                          className="change-status"
                          onClick={() => {
                            setShowStatusForm(!showStatusForm);
                            getStatusContrato();
                          }}
                        >
                          {!showStatusForm ? 'Alterar status' : 'voltar'}
                        </div>
                      )}
                    </>
                  )}
                </WrapRow>

                {showStatusForm && (
                  <Form onSubmit={changeStatus}>
                    <WrapRow style={{ marginBottom: '20px' }}>
                      <WrapRowItem>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 }),
                            // input: styles => ({ ...styles }),
                          }}
                          placeholder={''}
                          onChange={handleChangeSelectedStatus}
                          options={optionsStatus}
                          value={selectedStatus}
                        />
                      </WrapRowItem>

                      {selectedStatus.value &&
                        selectedStatus.label !== 'pagamento permitido' &&
                        selectedStatus.label !== 'cancelado' &&
                        selectedStatus.label !== 'erro de permissão' && (
                          <WrapRowItem>
                            <FormInputMaskSimple
                              name="data_status"
                              type="text"
                              placeholder={`${
                                selectedContrato.status?.slug === 'aceite'
                                  ? 'Data de Permissão do Pagamento'
                                  : ''
                              }${
                                selectedContrato.status?.slug ===
                                'pagamento_permitido'
                                  ? 'Previsão de Pagamento'
                                  : ''
                              }`}
                              mask="99/99/9999"
                            />
                          </WrapRowItem>
                        )}

                      {selectedStatus.value &&
                        selectedStatus.label === 'cancelado' && (
                          <WrapRowItem>
                            <Select
                              styles={{
                                // Fixes the overlapping problem of the component
                                menu: provided => ({
                                  ...provided,
                                  zIndex: 9999,
                                }),
                                // input: styles => ({ ...styles }),
                              }}
                              placeholder={''}
                              onChange={handleChangeSelectedStatusCancelamento}
                              options={optionsStatusCancelamento}
                              value={selectedStatusCancelamento}
                            />
                          </WrapRowItem>
                        )}

                      {selectedStatus.value && (
                        <ButtonStatus type="submit">
                          Alterar Status
                        </ButtonStatus>
                      )}
                    </WrapRow>
                  </Form>
                )}
                {/* <div className="wrapper-data-info">
                    {selectedContrato && selectedContrato.status ? selectedContrato.status?.descricao : ""}
                  </div> */}
                <div className="wrapper-timeline">
                  <Timeline
                    items={
                      selectedContrato.timeline ? selectedContrato.timeline : []
                    }
                  />
                </div>
              </div>

              <div className="wrapper-data">
                <div className="wrapper-data-label">Especialista:</div>
                <div className="wrapper-data-info">
                  Nome: {selectedContrato.especialista?.dados_usuario?.nome}
                </div>
                <div className="wrapper-data-info">
                  Email: {selectedContrato.especialista?.email}
                </div>
                <div className="wrapper-data-info">
                  CPF:{' '}
                  {cpf.format(
                    selectedContrato.especialista?.dados_usuario?.cpf,
                  )}
                </div>
                <div className="wrapper-data-info">
                  Matricula:{' '}
                  {selectedContrato.especialista?.dados_usuario?.matricula}
                </div>
              </div>

              <div className="wrapper-data">
                <div className="wrapper-data-label">Remetente:</div>
                <div className="wrapper-data-info">
                  Nome: {selectedContrato.remetente?.dados_usuario?.nome}
                </div>
                <div className="wrapper-data-info">
                  Email: {selectedContrato.remetente?.email}
                </div>
              </div>

              {/* <div className="wrapper-data">
                <div className="wrapper-data-label">
                  {selectedClient.tem_curadoria ? "Curador Responsável:" : "Responsável:"}
                </div>
                <div className="wrapper-data-info">
                  {selectedContrato.tema?.curador_responsavel}
                </div>
              </div> */}

              <div className="wrapper-data">
                <div className="wrapper-data-label">Dados do Contrato:</div>
                <div className="wrapper-data-info">
                  Tipo de Contratação:
                  {selectedContrato.tipo_contratacao === 'pessoa_juridica'
                    ? ' Pessoa Juridica'
                    : ''}
                  {selectedContrato.tipo_contratacao === 'pessoa_fisica'
                    ? ' Pessoa Física'
                    : ''}
                  {selectedContrato.tipo_contratacao === 'interno'
                    ? ' Interno'
                    : ''}
                </div>
                {selectedContrato.matricula &&
                  selectedContrato.tipo_contratacao === 'interno' && (
                    <div className="wrapper-data-info">
                      Matricula Especialista: {selectedContrato.matricula}
                    </div>
                  )}
                <div className="wrapper-data-info">
                  Data Contrato: {dataContrato}
                </div>
                {selectedContrato.data_prestacao_servico ? (
                  <>
                    <div className="wrapper-data-info">
                      Prazo de Entrega: {dataPrestacaoServico}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="wrapper-data-info">
                      Prazo de Entrega: {dataPrazoEntrega}
                    </div>
                  </>
                )}
                <div className="wrapper-data-info">
                  Data Pagamento Permitido: {dataPagamentoPermitido}
                </div>
                <div className="wrapper-data-info">
                  Data Pagamento Comandado: {dataPagamentoComandado}
                </div>
                <div className="wrapper-data-info">
                  Previsão de Pagamento: {dataPagamentoPrevisao}
                </div>
                {selectedContrato && selectedContrato.status_cancelamento && (
                  <div className="wrapper-data-info">
                    Motivo Cancelamento:{' '}
                    {selectedContrato.status_cancelamento?.descricao}
                  </div>
                )}
                <div className="wrapper-data-info">
                  {selectedContrato.cliente?.nome === 'Ensino'
                    ? 'Informações importantes para o docente:'
                    : 'Observações:'}
                  <div
                    style={{ marginTop: '10px', marginLeft: '10px' }}
                    dangerouslySetInnerHTML={{
                      __html: selectedContrato.observacoes?.replace(
                        /\n/g,
                        '<br>',
                      ),
                    }}
                  />
                </div>
              </div>

              {selectedContrato.tipo_contratacao !== 'interno' && (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">
                    Dados para Pagamento:
                  </div>
                  <div className="wrapper-data-info">
                    Tipo de Conta Bancária:
                    {selectedContrato.dados_pagamento?.modelo_pagamento === 'pf'
                      ? ' Pessoa Física'
                      : ''}
                    {selectedContrato.dados_pagamento?.modelo_pagamento === 'pj'
                      ? ' Pessoa Jurídica'
                      : ''}
                    {selectedContrato.dados_pagamento?.modelo_pagamento ===
                    'interno'
                      ? ' Interno'
                      : ''}
                  </div>
                  <div className="wrapper-data-info">
                    Banco: {selectedContrato.dados_pagamento?.banco}
                  </div>
                  <div className="wrapper-data-info">
                    Agência: {selectedContrato.dados_pagamento?.agencia}
                  </div>
                  <div className="wrapper-data-info">
                    Conta Corrente: {selectedContrato.dados_pagamento?.conta}
                  </div>
                  <div className="wrapper-data-info">
                    Digito Verificador:{' '}
                    {selectedContrato.dados_pagamento?.digito_verificador}
                  </div>
                </div>
              )}

              {selectedContrato.tipo_contratacao === 'pessoa_juridica' && (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">
                    Dados Pessoa Jurídica:
                  </div>
                  <div className="wrapper-data-info">
                    CNPJ:{' '}
                    {cnpj.format(
                      selectedContrato.especialista?.dados_usuario?.pj_cnpj,
                    )}
                  </div>
                  <div className="wrapper-data-info">
                    Razão social:{' '}
                    {pj.razao_social ||
                      selectedContrato.especialista?.dados_usuario
                        ?.pj_razao_social}
                  </div>
                </div>
              )}

              <div className="wrapper-data">
                <WrapRow>
                  <WrapRowItem>
                    <div className="wrapper-data-label">Documentos:</div>
                  </WrapRowItem>

                  <div
                    className="change-status"
                    onClick={() =>
                      history.push('/contratos/documentos/upload', {
                        selectedItem: selectedContrato,
                      })
                    }
                  >
                    Enviar documentos
                  </div>
                </WrapRow>

                {arquivos.map((arq: any) => (
                  <div key={arq.arquivo.id}>
                    <div className="wrapper-data-info">
                      <a
                        className="link_upload"
                        href={`${arq.arquivo.url}?token=${token}`}
                        target="_blank"
                      >
                        {arq.titulo}
                      </a>
                    </div>
                  </div>
                ))}
              </div>

              <div className="wrapper-data">
                <div className="wrapper-data-label">Contratos:</div>
                {selectedContrato.arquivo_contrato ? (
                  <div className="wrapper-data-info">
                    <a
                      className="link_upload"
                      href={`${selectedContrato.arquivo_contrato.url}?token=${token}`}
                      target="_blank"
                    >
                      Contrato de Prestação de Serviços
                    </a>
                  </div>
                ) : (
                  <div className="wrapper-data-info">
                    <span className="link_upload">
                      Contrato de Prestação de Serviços: Não gerado
                    </span>
                  </div>
                )}
                {selectedContrato.arquivo_direito_imagem && (
                  <div className="wrapper-data-info">
                    <a
                      className="link_upload"
                      href={`${selectedContrato.arquivo_direito_imagem.url}?token=${token}`}
                      target="_blank"
                    >
                      Termo de Autorização de Direitos de Imagem, Nome e Voz
                    </a>
                  </div>
                )}
              </div>

              <div className="wrapper-data">
                <WrapRow>
                  <WrapRowItem>
                    <div className="wrapper-data-label">Redlist:</div>
                  </WrapRowItem>

                  <div
                    className="change-status"
                    onClick={() => {
                      setShowCadastrarBlacklist(!showCadastrarBlacklist);
                    }}
                  >
                    {showCadastrarBlacklist ? 'Voltar' : 'Cadastrar ocorrência'}
                  </div>
                </WrapRow>

                {showCadastrarBlacklist && (
                  <Form ref={formRef} onSubmit={handleSubmit}>
                    <WrapRow>
                      <WrapRowItem style={{ marginTop: '15px' }}>
                        <label>Motivo:</label>
                        <Select
                          styles={{
                            // Fixes the overlapping problem of the component
                            menu: provided => ({ ...provided, zIndex: 9999 }),
                            // input: styles => ({ ...styles }),
                          }}
                          placeholder={''}
                          onChange={handleChangeSelectedStatusBlacklist}
                          options={optionsStatusBlacklist}
                          value={selectedStatusBlacklist}
                        />
                      </WrapRowItem>
                    </WrapRow>

                    <WrapRow style={{ marginTop: '15px' }}>
                      <WrapRowItem>
                        <FormInput
                          name="descricao"
                          type="text"
                          placeholder="Descrição"
                        />
                      </WrapRowItem>
                    </WrapRow>

                    <Button
                      type="submit"
                      loading={loading}
                      style={{ marginBottom: '15px' }}
                    >
                      Cadastrar na Redlist
                    </Button>
                  </Form>
                )}

                <div style={{ marginLeft: '5px' }}>
                  {selectedContrato.blacklist &&
                  selectedContrato.blacklist.length > 0 ? (
                    <div className="wrapper-data-info">
                      {selectedContrato.blacklist.map((blacklist_item: any) => (
                        <div key={blacklist_item.id} className="blacklist-item">
                          <p>{blacklist_item.status_blacklist.descricao}</p>
                          <p>{blacklist_item.descricao}</p>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div className="wrapper-data-info">
                      Nenhuma ocorrência encontrada
                    </div>
                  )}
                </div>
              </div>

              {(selectedContrato.status?.slug === 'pagamento_permitido' ||
                selectedContrato.status?.slug === 'pagamento_comandado') &&
              selectedContrato.cliente?.nome !== 'Premium' ? (
                <div className="wrapper-data">
                  <div className="wrapper-data-label">Certificados:</div>
                  {selectedContrato.arquivo_certificado ? (
                    <div className="wrapper-data-info">
                      <a
                        className="link_upload"
                        href={`${selectedContrato.arquivo_certificado.url}?token=${token}`}
                        target="_blank"
                      >
                        Certificado de Produção
                      </a>
                    </div>
                  ) : (
                    <div className="wrapper-data-info">
                      <a
                        className="link_upload"
                        onClick={() => {
                          gerarCertificado(true);
                        }}
                      >
                        Gerar Certificado
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <></>
              )}

              <div className="wrapper-data">
                <div className="wrapper-data-label">Produtos:</div>

                {selectedContrato.produtos?.map((item: any) => (
                  <div key={item.id} className="wrapper-produto">
                    <div className="wrapper-data-label">{item.titulo}:</div>
                    <div className="wrapper-data-info">
                      Quantidade: {item.pivot.quantidade}
                    </div>
                    {item.pivot.justificativa ? (
                      <>
                        <div className="wrapper-data-info">
                          Valor: R$ {parseFloat(item.valor).toFixed(2)}
                        </div>
                        <div className="wrapper-data-info">
                          Valor Solicitado: R${' '}
                          {parseFloat(item.pivot.valor).toFixed(2)}
                        </div>

                        <div className="wrapper-data-info">
                          Valor total: R${' '}
                          {parseFloat(item.pivot.valor_total).toFixed(2)}
                        </div>

                        <div className="wrapper-data-info">
                          Justificativa: {item.pivot.justificativa}
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="wrapper-data-info">
                          Valor: R$ {parseFloat(item.valor).toFixed(2)}
                        </div>
                        <div className="wrapper-data-info">
                          Valor total: R${' '}
                          {parseFloat(item.pivot.valor_total).toFixed(2)}
                        </div>
                      </>
                    )}
                  </div>
                ))}
                {(selectedContrato.valor_total ||
                  selectedContrato.valor_total === 0) && (
                  <div className="wrapper-money">
                    <div
                      className="wrapper-data-label"
                      style={{
                        paddingTop: '25px',
                        fontSize: '30px',
                        color: '#999',
                      }}
                    >
                      R$ {parseFloat(selectedContrato.valor_total).toFixed(2)}
                    </div>
                  </div>
                )}
              </div>
            </ContratoData>
          </Card>
        </div>
      </Content>
    </Container>
  );
};

export default ContratosVisualizar;
