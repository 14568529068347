import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { FiTrash, FiPlus, FiEdit, FiX } from 'react-icons/fi';
import { useHistory, useLocation } from 'react-router-dom';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';

import Card from '../../components/Card';
import Loading from '../../components/Loading';

import api from '../../services/api';

import {
  Container,
  Content,
  TextField,
  ClearButton
} from './styles';

interface TreinamentosEspecialistaInterface {
  id: string;
  midia: string;
  id_treinamento_realizado: string;
  modelo: string;
  projeto: string;
  servico: string;
}

const EspecialistasTreinamento: React.FC = () => {
  const history = useHistory();
  const location: any = useLocation();

  // const { user } = useAuth();
  // const { addToast } = useToast();

  // const MySwal = withReactContent(Swal)

  const [loading, setLoading] = useState(true);
  const [treinamentosEspecialista, setTreinamentosEspecialista] = useState<TreinamentosEspecialistaInterface[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [clearSelectedRows, setClearSelectedRows] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    if (location && location.state) {
      setSelectedItem(location.state.selectedItem);
    }
  }, [location]);

  useEffect(() => {
    if (selectedItem) {
      //console.log("selectedItem", selectedItem);
      getTreinamentosEspecialista();
    }
  }, [selectedItem]);

  useEffect(() => {
    if (clearSelectedRows) {
      setClearSelectedRows(false)
    }
  }, [clearSelectedRows]);

  const getTreinamentosEspecialista = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`/treinamento-especialista/${selectedItem?.id}`);
      // console.log("response", `/treinamento-especialista/${selectedItem?.id}`, response.data);

      setTreinamentosEspecialista(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, [selectedItem]);

  // function goStoreForm() {
  //   history.push("/account/users/cadastro");
  // }

  function goUpdateForm() {
    history.push("/especialistas/visualizar", {
      selectedItem: selectedRows[0]
    });
  }

  const handleChange = (state: any) => {
    // You can use setState or dispatch with something like Redux so we can use the retrieved data
    //console.log('Selected Rows: ', state.selectedRows);

    setSelectedRows(state.selectedRows);
  };

  const actions = (
    <></>
    // <FiPlus className="icon" onClick={() => {
    //   goStoreForm();
    // }} />
  );

  const contextActions = (
    <>
      { selectedRows.length === 1 &&
        // <FiEdit className="icon" style={{ marginRight: "15px" }} onClick={() => {
        //   goUpdateForm();
        // }} />
        <button
          type="button"
          className="button-top-datatable"
          onClick={() => {
            goUpdateForm();
          }}
        >
          Visualizar
        </button>
      } 
    </>
  );

  function goBack() {
    history.goBack();
  }

  const filtered = useMemo(() => {    
    if (!filterText)
      return treinamentosEspecialista

    let search = filterText.toLowerCase();

    search = search.split("-").join("");
    search = search.split(".").join("");

    search = search.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

    return treinamentosEspecialista.filter((item:any) => {
      return item.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.projeto?.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.modelo?.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        item.servico?.titulo.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search) ||
        (item.realizado ? "Concluído" : "Pendente").toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "").includes(search)
    })
  }, [filterText, treinamentosEspecialista])

  const subHeaderComponent = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <>
        <TextField id="search" type="text" placeholder="Pesquisar" aria-label="Search Input" value={filterText} onChange={(e: any) => setFilterText(e.target.value)} />
        <ClearButton type="button" onClick={handleClear}>x</ClearButton>
      </>
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: 'Título',
      selector: 'titulo',
      sortable: false,
      cell: (row: any) => <p>{row.titulo}</p>,
    },
    {
      name: 'Projeto',
      selector: 'projeto',
      sortable: false,
      cell: (row: any) => <p>{row.projeto?.titulo}</p>,
    },
    {
      name: 'Modelo',
      selector: 'modelo',
      sortable: false,
      cell: (row: any) => <p>{row.modelo?.titulo}</p>,
    },
    {
      name: 'Serviço',
      selector: 'servico',
      sortable: false,
      cell: (row: any) => <p>{row.servico?.titulo}</p>,
    },
    {
      name: 'Status',
      selector: 'status',
      sortable: false,
      // right: true,
      cell: (row: any) => {
        return (
          <div style={{
            color: "#fff",
            backgroundColor:
            row.realizado ? "#95B759" : "#F09B0E",
            padding: "5px",
            borderRadius: "3px",
            width: '150px',
            textAlign: 'center'
          }}>
            {row.realizado ? <span>Concluído</span> : <span>Pendente</span>} 
          </div>
        )
      }
    },
  ];

  return (
    <Container>
      <Content>
        <Card>
          <div className="header" style={{ height: "0px", padding: "15px" }}>
            <h2>
              Treinamentos Realizados
            </h2>
            <FiX onClick={() => goBack()} className="icon" />
          </div>

          <div style={{ padding: "15px", borderBottom: "1px solid #F1F1F1", marginBottom: "15px" }}>
            <h3 style={{ marginBottom: "15px"}}>
              <b>Especialista:</b> { selectedItem?.dados_usuario?.nome }
            </h3>
            <h3 style={{ marginBottom: "15px"}}>
              <b>Email:</b> { selectedItem?.email }
            </h3>
          </div>
          
          <DataTable
            title={`Status dos Treinamentos:`}
            columns={columns}
            data={filtered}
            // selectableRows
            highlightOnHover
            onSelectedRowsChange={handleChange}
            defaultSortField="name"
            pagination
            actions={actions}
            contextActions={contextActions}
            subHeader
            subHeaderComponent={subHeaderComponent}
            noDataComponent="Nenhum registro encontrado."
            paginationComponentOptions={{
              rowsPerPageText: 'Itens por página',
              rangeSeparatorText: 'de'
            }}
            clearSelectedRows={clearSelectedRows}
            contextMessage={{
              singular: 'item selecionado',
              plural: 'itens selecionados',
              message: ''
            }}
            progressComponent={<Loading />}
            progressPending={loading}
          />
        </Card>
      </Content>
    </Container>
  );
};

export default EspecialistasTreinamento;
