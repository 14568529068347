import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import { cnpj } from 'cpf-cnpj-validator';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';
import * as Yup from 'yup';

import { useAuth } from '../../../../hooks/auth';
import { useToast } from '../../../../hooks/toast';
import convertUTCDateToLocalDate from '../../../../utils/convertUTCDateToLocalDate';
import getCountriesOptions from '../../../../utils/getCountriesOptions';
import getValidationErrors from '../../../../utils/getValidationErrors';

import Button from '../../../../components/Button';
import Card from '../../../../components/Card';
import FormInput from '../../../../components/FormInput';
import FormInputMask from '../../../../components/FormInputMask';

import api from '../../../../services/api';

import { Container, Content, WrapRow, WrapRowItem } from './styles';

interface ProfileFormData {
  nome: string;
  email: string;
  cpf: string;
  matricula: string;
  rg: string;
  rg_expedicao_orgao: string;
  rg_expedicao_data: string;
  estado_civil: string;
  data_nascimento: string;
  naturalidade: string;
  nacionalidade: string;
  telefone: string;
  celular: string;
  banco: string;
  agencia: string;
  conta: string;
  digito_verificador: string;
  cep: string;
  rua: string;
  numero: string;
  complemento: string;
  referencia: string;
  bairro: string;
  cidade: string;
  estado: string;
  cnpj: string;
  pis: string;
  razao_social: string;
  empresa_cep: string;
  empresa_rua: string;
  empresa_numero: string;
  empresa_complemento: string;
  empresa_cidade: string;
  empresa_bairro: string;
  empresa_estado: string;
}

interface BanksInterface {
  id: any;
}

interface OptionsData {
  value: any;
  label: string;
}

const Profile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();

  const { user, updateUser, roles, signOut } = useAuth();
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [contatos, setContatos] = useState([]);
  const [selectedContaPessoaFisica, setSelectedContaPessoaFisica] = useState<
    OptionsData
  >({} as OptionsData);
  const [optionsTipoContaBancaria, setOptionsTipoContaBancaria] = useState<
    OptionsData[]
  >([
    { value: true, label: 'Pessoa Física' },
    { value: false, label: 'Pessoa Jurídica' },
  ]);
  const [optionsTipoColaborador, setOptionsTipoColaborador] = useState<
    OptionsData[]
  >([
    { value: 'INTERNO', label: 'Colaborador Interno - Yduqs' },
    { value: 'PF', label: 'Pessoa Física' },
    { value: 'PJ', label: 'Pessoa Jurídica' },
  ]);
  const [selectedTipoColaborador, setSelectedTipoColaborador] = useState<
    OptionsData
  >({} as OptionsData);
  const [selectedEstadoCivil, setSelectedEstadoCivil] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [optionsEstadoCivil, setOptionsEstadoCivil] = useState<OptionsData[]>([
    { value: 'solteiro', label: 'Solteiro' },
    { value: 'casado', label: 'Casado' },
    { value: 'uniao estavel', label: 'União Estável' },
    { value: 'separado - judicialmente', label: 'Separado - judicialmente' },
    {
      value: 'separado - extrajudicialmente',
      label: 'Separado - extrajudicialmente',
    },
    { value: 'divorciado', label: 'Divorciado' },
    { value: 'viúvo', label: 'Viúvo' },
  ]);
  const [isNewUser, setIsNewUser] = useState(true);
  const [isEspecialista, setIsEspecialista] = useState(false);
  const [showErrorCNAE, setShowErrorCNAE] = useState(false);
  const [matricula, setMatricula] = useState('');
  const [cnpjNotFound, setCnpjNotFound] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<OptionsData>({
    value: 'BR',
    label: 'Brasil',
  });
  const [selectedSexo, setSelectedSexo] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [banks, setBanks] = useState<BanksInterface[]>([]);
  const [selectedBank, setSelectedBank] = useState<OptionsData>(
    {} as OptionsData,
  );
  const [optionsBank, setOptionsBank] = useState<OptionsData[]>([]);

  let countries = getCountriesOptions();

  useEffect(() => {
    if (user) {
      loadUserData();
      getBanks();
      formRef.current?.setFieldValue('email', user.email);
    }
  }, [user]);

  useEffect(() => {
    if (!roles) return;

    if (roles.length > 0) {
      if (roles.indexOf('especialista') > -1) {
        setIsEspecialista(true);
      }
    }
  }, [roles]);

  useEffect(() => {
    if (matricula) {
      setMatricula(matricula.trim());
    }
  }, [matricula]);

  useEffect(() => {
    if (banks) {
      const tmp: OptionsData[] = [];

      banks.forEach((item: any) => {
        if (item.code) {
          tmp.push({
            value: item.code,
            label: `${item.code} - ${item.fullName}`,
            // code: item.code
          });
        }
      });

      setOptionsBank(tmp);
    }
  }, [banks]);

  const loadUserData = useCallback(async () => {
    const response = await api.get(`/usuario/${user.id}`);
    //console.log("response loadUserData", response.data);

    if (response.data?.dados_usuario?.id) {
      setIsNewUser(false);

      formRef.current?.setFieldValue(
        'nome',
        response.data.dados_usuario.nome
          ? response.data.dados_usuario.nome
          : '',
      );
      formRef.current?.setFieldValue(
        'cpf',
        response.data.dados_usuario.cpf ? response.data.dados_usuario.cpf : '',
      );
      formRef.current?.setFieldValue(
        'pis',
        response.data.dados_usuario.pis ? response.data.dados_usuario.pis : '',
      );
      formRef.current?.setFieldValue(
        'rg',
        response.data.dados_usuario.rg ? response.data.dados_usuario.rg : '',
      );
      formRef.current?.setFieldValue(
        'rg_expedicao_orgao',
        response.data.dados_usuario.rg_expedicao_orgao
          ? response.data.dados_usuario.rg_expedicao_orgao
          : '',
      );

      if (response.data.dados_usuario.matricula) {
        setMatricula(response.data.dados_usuario.matricula);
        setSelectedTipoColaborador({
          value: 'INTERNO',
          label: 'Colaborador Interno - Yduqs',
        });
        formRef.current?.setFieldValue(
          'matricula',
          response.data.dados_usuario.matricula,
        );
      } else {
        if (response.data.dados_pagamento.conta_pessoa_fisica) {
          setSelectedTipoColaborador({ value: 'PF', label: 'Pessoa Física' });
        } else {
          setSelectedTipoColaborador({ value: 'PJ', label: 'Pessoa Jurídica' });
        }
      }

      if (response.data.dados_usuario.rg_expedicao_data) {
        let dt_rg_expedicao_data = convertUTCDateToLocalDate(
          new Date(response.data.dados_usuario.rg_expedicao_data),
        );
        formRef.current?.setFieldValue(
          'rg_expedicao_data',
          `${('0' + dt_rg_expedicao_data.getDate()).slice(-2)}/${(
            '0' +
            (dt_rg_expedicao_data.getMonth() + 1)
          ).slice(-2)}/${dt_rg_expedicao_data.getFullYear()}`,
        );
      }

      if (response.data.dados_usuario.data_nascimento) {
        let dt_data_nascimento = convertUTCDateToLocalDate(
          new Date(response.data.dados_usuario.data_nascimento),
        );
        formRef.current?.setFieldValue(
          'data_nascimento',
          `${('0' + dt_data_nascimento.getDate()).slice(-2)}/${(
            '0' +
            (dt_data_nascimento.getMonth() + 1)
          ).slice(-2)}/${dt_data_nascimento.getFullYear()}`,
        );
      }

      setSelectedEstadoCivil(
        response.data.dados_usuario.estado_civil
          ? {
              value: response.data.dados_usuario.estado_civil,
              label:
                response.data.dados_usuario.estado_civil
                  .charAt(0)
                  .toUpperCase() +
                response.data.dados_usuario.estado_civil.slice(1),
            }
          : ({} as OptionsData),
      );

      setSelectedSexo(
        response.data.dados_usuario.sexo
          ? {
              value: response.data.dados_usuario.sexo,
              label:
                response.data.dados_usuario.sexo === 'M'
                  ? 'Masculino'
                  : response.data.dados_usuario.sexo === 'F'
                  ? 'Feminino'
                  : 'Não-binário/Outros',
            }
          : ({} as OptionsData),
      );

      formRef.current?.setFieldValue(
        'nacionalidade',
        response.data.dados_usuario.nacionalidade
          ? response.data.dados_usuario.nacionalidade
          : '',
      );
      formRef.current?.setFieldValue(
        'naturalidade',
        response.data.dados_usuario.naturalidade
          ? response.data.dados_usuario.naturalidade
          : '',
      );

      if (response.data.dados_pagamento) {
        // formRef.current?.setFieldValue("banco", response.data.dados_pagamento.banco ? response.data.dados_pagamento.banco : "");
        formRef.current?.setFieldValue(
          'agencia',
          response.data.dados_pagamento.agencia
            ? response.data.dados_pagamento.agencia
            : '',
        );
        formRef.current?.setFieldValue(
          'conta',
          response.data.dados_pagamento.conta
            ? response.data.dados_pagamento.conta
            : '',
        );
        formRef.current?.setFieldValue(
          'digito_verificador',
          response.data.dados_pagamento?.digito_verificador,
        );

        setSelectedContaPessoaFisica(
          response.data.dados_pagamento.conta_pessoa_fisica
            ? { value: true, label: 'Pessoa Física' }
            : { value: false, label: 'Pessoa Jurídica' },
        );
        setSelectedBank(
          response.data.dados_pagamento.banco &&
            response.data.dados_pagamento.numero_banco
            ? {
                value: response.data.dados_pagamento.numero_banco,
                label: `${response.data.dados_pagamento.numero_banco} - ${response.data.dados_pagamento.banco}`,
              }
            : ({} as OptionsData),
        );
      }

      formRef.current?.setFieldValue(
        'cep',
        response.data.endereco.cep ? response.data.endereco.cep : '',
      );
      formRef.current?.setFieldValue(
        'rua',
        response.data.endereco.rua ? response.data.endereco.rua : '',
      );
      formRef.current?.setFieldValue(
        'numero',
        response.data.endereco.numero ? response.data.endereco.numero : '',
      );
      formRef.current?.setFieldValue(
        'complemento',
        response.data.endereco.complemento
          ? response.data.endereco.complemento
          : '',
      );
      formRef.current?.setFieldValue(
        'bairro',
        response.data.endereco.bairro ? response.data.endereco.bairro : '',
      );
      formRef.current?.setFieldValue(
        'cidade',
        response.data.endereco.cidade ? response.data.endereco.cidade : '',
      );
      formRef.current?.setFieldValue(
        'estado',
        response.data.endereco.uf ? response.data.endereco.uf : '',
      );
      formRef.current?.setFieldValue(
        'cnpj',
        response.data.dados_usuario.pj_cnpj
          ? response.data.dados_usuario.pj_cnpj
          : '',
      );

      if (response.data.endereco.pais) {
        const optCountry = countries.find(
          (item: any) => item.label === response.data.endereco.pais,
        );

        if (optCountry) {
          setSelectedCountry(optCountry);

          setTimeout(() => {
            formRef.current?.setFieldValue(
              'cep',
              response.data.endereco.cep ? response.data.endereco.cep : '',
            );
            formRef.current?.setFieldValue(
              'estado',
              response.data.endereco.uf ? response.data.endereco.uf : '',
            );

            if (
              response.data.contatos.find(
                (item: any) => item.tipo === 'telefone',
              )
            ) {
              formRef.current?.setFieldValue(
                'telefone',
                response.data.contatos.find(
                  (item: any) => item.tipo === 'telefone',
                )?.conteudo,
              );
            }

            if (
              response.data.contatos.find(
                (item: any) => item.tipo === 'celular',
              )
            ) {
              formRef.current?.setFieldValue(
                'celular',
                response.data.contatos.find(
                  (item: any) => item.tipo === 'celular',
                )?.conteudo,
              );
            }
          }, 500);
        }
      }

      formRef.current?.setFieldValue(
        'razao_social',
        response.data?.dados_usuario?.pj_razao_social
          ? response.data.dados_usuario.pj_razao_social
          : '',
      );
      formRef.current?.setFieldValue(
        'empresa_cep',
        response.data?.dados_usuario?.pj_cep
          ? response.data.dados_usuario.pj_cep
          : '',
      );
      formRef.current?.setFieldValue(
        'empresa_rua',
        response.data?.dados_usuario?.pj_rua
          ? response.data.dados_usuario.pj_rua
          : '',
      );
      formRef.current?.setFieldValue(
        'empresa_numero',
        response.data?.dados_usuario?.pj_numero
          ? response.data.dados_usuario.pj_numero
          : '',
      );
      formRef.current?.setFieldValue(
        'empresa_complemento',
        response.data?.dados_usuario?.pj_complemento
          ? response.data.dados_usuario.pj_complemento
          : '',
      );
      formRef.current?.setFieldValue(
        'empresa_cidade',
        response.data?.dados_usuario?.pj_cidade
          ? response.data.dados_usuario.pj_cidade
          : '',
      );
      formRef.current?.setFieldValue(
        'empresa_bairro',
        response.data?.dados_usuario?.pj_bairro
          ? response.data.dados_usuario.pj_bairro
          : '',
      );
      formRef.current?.setFieldValue(
        'empresa_estado',
        response.data?.dados_usuario?.pj_estado
          ? response.data.dados_usuario.pj_estado
          : '',
      );

      setContatos(response.data.contatos);

      // if(response.data.dados_usuario.pj_cnpj){
      //   searchCNPJ(response.data.dados_usuario.pj_cnpj);
      // }

      // console.log("response.data", response.data);
      updateUser();
    }
  }, []);

  const handleSubmit = useCallback(
    async (data: ProfileFormData) => {
      //console.log("handleSubmit data", data);

      if (!selectedEstadoCivil.value) {
        addToast({
          type: 'error',
          title: 'Selecione o estado civil',
          description: '',
        });
        return;
      }

      if (!selectedCountry.value) {
        addToast({
          type: 'error',
          title: 'Selecione o País',
          description: '',
        });
        return;
      }

      if (!selectedSexo.value) {
        addToast({
          type: 'error',
          title: 'Selecione o Sexo',
          description: '',
        });
        return;
      }

      if (!matricula) {
        if (
          !(
            selectedContaPessoaFisica.value === true ||
            selectedContaPessoaFisica.value === false
          )
        ) {
          addToast({
            type: 'error',
            title: 'Selecione o tipo de conta bancária',
            description: '',
          });
          return;
        }
      }

      if (showErrorCNAE) {
        addToast({
          type: 'error',
          title:
            'O CNPJ incluído não possui CNAEs aceitos pela Yduqs para contratação',
          description: 'Entre em contato no caso de dúvidas',
        });
        return;
      }

      if (!matricula) {
        if (selectedContaPessoaFisica.value === true && data.cnpj) {
          addToast({
            type: 'error',
            title: 'Tipo de conta bancária do tipo Pessoa Física',
            description:
              'Foi adicionado um CNPJ, é preciso cadastrar uma conta bancária para Pessoa Jurídica',
          });
          return;
        }

        if (selectedContaPessoaFisica.value === false && !data.cnpj) {
          addToast({
            type: 'error',
            title: 'Tipo de conta bancária do tipo Pessoa Jurídica',
            description: 'É preciso cadastrar o CNPJ',
          });
          return;
        }

        if (selectedContaPessoaFisica.value === true && !data.cnpj) {
          if (!data.pis) {
            addToast({
              type: 'error',
              title: 'PIS obrigatório',
              description: '',
            });
            return;
          }
        }

        if (!selectedBank.value) {
          addToast({
            type: 'error',
            title: 'Selecione o banco',
            description: '',
          });
          return;
        }
      } else {
        if (matricula && matricula.length <= 6) {
          addToast({
            type: 'error',
            title: 'Matrícula inválida',
            description: 'Preencha uma matrícula válida',
          });
          return;
        }
      }

      if (!validarPIS(data.pis)) {
        addToast({
          type: 'error',
          title: 'PIS/PASEP/NIT inválido',
          description: '',
        });
        return;
      }

      if (!validarNome(data.nome)) {
        addToast({
          type: 'error',
          title: 'Nome inválido',
          description: '',
        });
        return;
      }

      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const params_schema: any = {
          nome: Yup.string()
            .required('Nome obrigatório')
            .test('len', 'Digite um nome válido', val => val.length >= 6),
          email: Yup.string()
            .required('E-mail obrigatório')
            .email('Digite um e-mail válido'),
          cpf: Yup.string()
            .required('CPF é obrigatório')
            .test('len', 'Digite um CPF válido', val => isValidCPF(val)),
          // matricula: Yup.string().required('Matrícula é obrigatório'),
          rg: Yup.string().required('RG é obrigatório'),
          rg_expedicao_orgao: Yup.string().required(
            'Órgão de expedição é obrigatório',
          ),
          rg_expedicao_data: Yup.string().required(
            'Data de emissão é obrigatório',
          ),
          data_nascimento: Yup.string().required(
            'Data de nascimento é obrigatório',
          ),
          naturalidade: Yup.string().required('Naturalidade é obrigatório'),
          nacionalidade: Yup.string().required('Nacionalidade é obrigatório'),
          // telefone: Yup.string().required('Número de telefone é obrigatório'),
          celular: Yup.string().required('Número de celular é obrigatório'),
          // banco: Yup.string().required('Nome do banco é obrigatório'),
          // agencia: Yup.string().required('Número da agência é obrigatório'),
          // conta: Yup.string().required('Número da conta é obrigatório'),
          // digito_verificador: Yup.string().required('Dígito verificador é obrigatório'),
          cep: Yup.string().required('CEP é obrigatório'),
          rua: Yup.string().required('Nome da rua é obrigatório'),
          numero: Yup.string().required('Número é obrigatório'),
          bairro: Yup.string().required('Bairro é obrigatório'),
          cidade: Yup.string().required('Cidade é obrigatório'),
          estado: Yup.string().required('Estado é obrigatório'),
        };

        if (!matricula) {
          // params_schema.banco = Yup.string().required('Nome do banco é obrigatório');
          params_schema.agencia = Yup.string().required(
            'Número da agência é obrigatório',
          );
          params_schema.conta = Yup.string().required(
            'Número da conta é obrigatório',
          );
          // params_schema.digito_verificador = Yup.string().required('Dígito verificador é obrigatório');
        }

        if (data.cnpj && selectedContaPessoaFisica.value === false) {
          params_schema.cnpj = Yup.string()
            .required('CNPJ obrigatório')
            .test('len', 'Digite um CNPJ válido', val => cnpj.isValid(val));
          params_schema.razao_social = Yup.string().required(
            'Razão Social é obrigatório',
          );
          params_schema.empresa_cep = Yup.string().required(
            'CEP da empresa é obrigatório',
          );
          params_schema.empresa_rua = Yup.string().required(
            'Rua da empresa é obrigatório',
          );
          params_schema.empresa_numero = Yup.string().required(
            'Número da empresa é obrigatório',
          );
          // params_schema.empresa_complemento = Yup.string().required('Complemento da empresa é obrigatório');
          params_schema.empresa_cidade = Yup.string().required(
            'Cidade da empresa é obrigatório',
          );
          params_schema.empresa_bairro = Yup.string().required(
            'Bairro da empresa é obrigatório',
          );
          params_schema.empresa_estado = Yup.string().required(
            'UF da empresa é obrigatório',
          );
        }

        const schema = Yup.object().shape(params_schema);

        await schema.validate(data, {
          abortEarly: false,
        });

        let dt_data_nascimento = data.data_nascimento.split('/');
        let dt_rg_expedicao_data = data.rg_expedicao_data.split('/');

        const today = new Date();

        if (
          parseInt(dt_data_nascimento[1]) > 12 ||
          parseInt(dt_data_nascimento[0]) > 31
        ) {
          addToast({
            type: 'error',
            title: 'Data de nascimento inválida',
            description: '',
          });
          return;
        }

        if (dt_data_nascimento[2].replace('_', '').length !== 4) {
          addToast({
            type: 'error',
            title: 'Data de nascimento inválida',
            description: '',
          });
          return;
        }

        if (parseInt(dt_data_nascimento[2]) > today.getFullYear()) {
          addToast({
            type: 'error',
            title: 'Data de nascimento inválida',
            description: '',
          });
          return;
        }

        if (
          parseInt(dt_rg_expedicao_data[1]) > 12 ||
          parseInt(dt_rg_expedicao_data[0]) > 31
        ) {
          addToast({
            type: 'error',
            title: 'Data de expedição do RG inválida',
            description: '',
          });
          return;
        }

        if (dt_rg_expedicao_data[2].replace('_', '').length !== 4) {
          addToast({
            type: 'error',
            title: 'Data de expedição do RG inválida',
            description: '',
          });
          return;
        }

        if (parseInt(dt_rg_expedicao_data[2]) > today.getFullYear()) {
          addToast({
            type: 'error',
            title: 'Data de expedição do RG inválida',
            description: '',
          });
          return;
        }

        const params: any = {
          dados_usuario: {
            nome: data.nome.trim(),
            cpf: data.cpf
              .split('_')
              .join('')
              .split('.')
              .join('')
              .split('-')
              .join(''),
            matricula: data.matricula ? data.matricula.trim() : null,
            rg: data.rg.trim(),
            rg_expedicao_orgao: data.rg_expedicao_orgao,
            rg_expedicao_data: `${dt_rg_expedicao_data[2]}-${dt_rg_expedicao_data[1]}-${dt_rg_expedicao_data[0]}`,
            pis: data.pis
              ? data.pis
                  .replace('_', '')
                  .replace('.', '')
                  .replace('.', '')
                  .replace('-', '')
              : '',
            estado_civil: selectedEstadoCivil.value,
            sexo: selectedSexo.value,
            data_nascimento: `${dt_data_nascimento[2]}-${dt_data_nascimento[1]}-${dt_data_nascimento[0]}`,
            naturalidade: data.naturalidade,
            nacionalidade: data.nacionalidade,
            pj_cnpj: data.cnpj
              ? data.cnpj
                  .split('_')
                  .join('')
                  .split('.')
                  .join('')
                  .split('-')
                  .join('')
                  .split('/')
                  .join('')
              : '',
            pj_razao_social: data.razao_social,
            pj_cep: data.empresa_cep ? data.empresa_cep.replace('-', '') : '',
            pj_rua: data.empresa_rua,
            pj_numero: data.empresa_numero,
            pj_complemento: data.empresa_complemento,
            pj_cidade: data.empresa_cidade,
            pj_bairro: data.empresa_bairro,
            pj_estado: data.empresa_estado,
            pj_uf: data.empresa_estado,
          },
          endereco: {
            pais: selectedCountry.label,
            cep:
              selectedCountry.value === 'BR'
                ? data.cep.replace('-', '')
                : data.cep,
            rua: data.rua,
            numero: data.numero,
            complemento: data.complemento,
            // referencia_localizacao: data.referencia,
            bairro: data.bairro,
            cidade: data.cidade,
            estado: data.estado,
            uf: data.estado,
          },
          // dados_pagamento: {
          //   banco: data.banco,
          //   agencia: data.agencia,
          //   conta : data.conta,
          //   digito_verificador: data.digito_verificador,
          //   conta_pessoa_fisica: selectedContaPessoaFisica.value
          // },
          contatos: [],
        };

        if (!matricula) {
          const banco = selectedBank.label.split(' - ');

          params.dados_pagamento = {
            numero_banco: selectedBank.value,
            banco: selectedBank.label.replace(`${banco[0]} - `, ''),
            agencia: data.agencia,
            conta: data.conta,
            digito_verificador: data.digito_verificador,
            conta_pessoa_fisica: selectedContaPessoaFisica.value,
          };
        }

        let tmp: any = [];

        let found_email = false;
        let found_telefone = false;
        let found_celular = false;

        contatos.forEach((item: any) => {
          if (item.tipo === 'email') {
            tmp.push({
              id: item.id,
              tipo: 'email',
              conteudo: data.email,
            });

            found_email = true;
          }
          if (item.tipo === 'telefone') {
            tmp.push({
              id: item.id,
              tipo: 'telefone',
              conteudo: data.telefone,
            });

            found_telefone = true;
          }
          if (item.tipo === 'celular') {
            tmp.push({
              id: item.id,
              tipo: 'celular',
              conteudo: data.celular,
            });

            found_celular = true;
          }
        });

        if (found_email === false) {
          tmp.push({
            tipo: 'email',
            conteudo: data.email,
          });
        }

        if (found_telefone === false) {
          tmp.push({
            tipo: 'telefone',
            conteudo: data.telefone,
          });
        }

        if (found_celular === false) {
          tmp.push({
            tipo: 'celular',
            conteudo: data.celular,
          });
        }

        params.contatos = tmp;

        if (user.email !== data.email.trim()) {
          const user_response = await Swal.fire({
            title:
              '<strong>Tem certeza que deseja trocar o seu e-mail?</strong>',
            icon: 'warning',
            html:
              'Ao confirmar você será deslogado do sistema e precisará realizar o login com o novo e-mail',
            showCloseButton: true,
            showCancelButton: true,
            focusConfirm: true,
            confirmButtonText: 'Confirmar',
            confirmButtonAriaLabel: 'Confirmar',
            cancelButtonText: 'Voltar',
            cancelButtonAriaLabel: 'Voltar',
            confirmButtonColor: '#1CAEBD',
            cancelButtonColor: '#312e38',
            reverseButtons: true,
          });

          if (!user_response.isConfirmed) {
            return;
          } else {
            params.email = data.email.trim();
          }
        }

        // console.log("params", params);

        await api.put(`usuario/${user.id}`, params);

        addToast({
          type: 'success',
          title: 'Dados atualizados com sucesso',
          description: '',
        });

        loadUserData();

        if (user.email !== data.email.trim()) {
          signOut();
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro ao atualizar dados',
            description: 'Verifique os dados a serem cadastrados',
          });

          return;
        }

        // console.log("err", err.response?.data)

        for (let error in err.response?.data) {
          // console.log(error)
          addToast({
            type: 'error',
            title: 'Erro ao atualizar dados',
            description: err.response?.data[error].message
              ? err.response.data[error].message
              : 'Ocorreu um erro ao atualizar os dados do usuário.',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [
      history,
      addToast,
      isNewUser,
      contatos,
      selectedContaPessoaFisica,
      selectedEstadoCivil,
      showErrorCNAE,
      matricula,
      selectedCountry,
      selectedSexo,
      selectedBank,
    ],
  );

  function handleChangeSelectedContaPessoaFisica(
    newValue: any,
    actionMeta: any,
  ) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedContaPessoaFisica(newValue);
  }

  function handleChangeSelectedBank(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedBank(newValue);
  }

  function handleChangeSelectedTipoColaborador(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    // if(newValue.value === "PF"){
    //   setSelectedContaPessoaFisica({value: true, label: "Pessoa Física"});
    // }else{
    //   setSelectedContaPessoaFisica({value: false, label: "Pessoa Jurídica"});
    // }
    setMatricula('');

    setSelectedTipoColaborador(newValue);
  }

  function handleChangeSelectedEstadoCivil(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedEstadoCivil(newValue);
  }

  function handleChangeSelectedSexo(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedSexo(newValue);
  }

  function handleChangeSelectedCountry(newValue: any, actionMeta: any) {
    // console.group('Value Changed');
    //console.log(newValue);
    //console.log(`action: ${actionMeta.action}`);
    // console.groupEnd();

    //console.log("newValue", newValue.value);

    setSelectedCountry(newValue);
  }

  const searchCEP = useCallback(async cep => {
    if (cep.length > 0) {
      const cep_final = cep.split('_').join('').replace('-', '');

      if (cep_final.length === 8) {
        const response = await api.get(
          `https://brasilapi.com.br/api/cep/v1/${cep_final}`,
        );
        //console.log("cep:", response);

        if (response.data) {
          formRef.current?.setFieldValue('rua', response.data.street);
          formRef.current?.setFieldValue('cidade', response.data.city);
          formRef.current?.setFieldValue('bairro', response.data.neighborhood);
          formRef.current?.setFieldValue('estado', response.data.state);
        }
      }
    }
  }, []);

  const searchCEP_PJ = useCallback(async cep => {
    if (cep.length > 0) {
      const cep_final = cep.split('_').join('').replace('-', '');

      if (cep_final.length === 8) {
        //console.log("???????????")
        const response = await api.get(
          `https://brasilapi.com.br/api/cep/v1/${cep_final}`,
        );
        //console.log("cep:", response);

        if (response.data) {
          formRef.current?.setFieldValue('empresa_rua', response.data.street);
          formRef.current?.setFieldValue('empresa_cidade', response.data.city);
          formRef.current?.setFieldValue(
            'empresa_bairro',
            response.data.neighborhood,
          );
          formRef.current?.setFieldValue('empresa_estado', response.data.state);
        }
      }
    }
  }, []);

  const formatUF = useCallback(async (uf, cnpj = false) => {
    if (cnpj) {
      formRef.current?.setFieldValue('empresa_estado', uf.toUpperCase());
    } else {
      formRef.current?.setFieldValue('estado', uf.toUpperCase());
    }
  }, []);

  const searchCNPJ = useCallback(async input_cnpj => {
    if (input_cnpj.length > 0) {
      const cnpj_final = input_cnpj
        .split('_')
        .join('')
        .replace('-', '')
        .split('.')
        .join('')
        .replace('/', '');
      //console.log("cnpj_final:", cnpj_final);

      if (!cnpj.isValid(cnpj_final)) {
        formRef.current?.setFieldError('cnpj', 'CNPJ inválido');
      } else {
        formRef.current?.setFieldError('cnpj', '');
      }

      if (cnpj_final.length === 14 && cnpj.isValid(cnpj_final)) {
        try {
          const response = await api.get(
            `https://brasilapi.com.br/api/cnpj/v1/${cnpj_final}`,
          );
          //console.log("cnpj:", response);

          if (response.data) {
            setCnpjNotFound(false);
            // const response_cep = await api.get(`https://brasilapi.com.br/api/cep/v1/${response.data.cep}`);
            //console.log("cnpj cep:", response_cep);

            formRef.current?.setFieldValue(
              'razao_social',
              response.data.razao_social,
            );
            formRef.current?.setFieldValue('empresa_cep', response.data.cep);
            formRef.current?.setFieldValue(
              'empresa_rua',
              response.data.logradouro,
            );
            formRef.current?.setFieldValue(
              'empresa_numero',
              response.data.numero,
            );
            formRef.current?.setFieldValue(
              'empresa_complemento',
              response.data.complemento,
            );
            formRef.current?.setFieldValue(
              'empresa_cidade',
              response.data.municipio,
            );
            formRef.current?.setFieldValue(
              'empresa_bairro',
              response.data.bairro,
            );
            formRef.current?.setFieldValue('empresa_estado', response.data.uf);

            checkCNAES(response.data);
          } else {
            setCnpjNotFound(true);
            setShowErrorCNAE(false);
          }
        } catch (error) {
          setCnpjNotFound(true);
          setShowErrorCNAE(false);
        }
      } else {
        formRef.current?.setFieldValue('razao_social', '');
        formRef.current?.setFieldValue('empresa_cep', '');
        formRef.current?.setFieldValue('empresa_rua', '');
        formRef.current?.setFieldValue('empresa_numero', '');
        formRef.current?.setFieldValue('empresa_complemento', '');
        formRef.current?.setFieldValue('empresa_cidade', '');
        formRef.current?.setFieldValue('empresa_bairro', '');
        formRef.current?.setFieldValue('empresa_estado', '');
      }
    } else {
      formRef.current?.setFieldValue('razao_social', '');
      formRef.current?.setFieldValue('empresa_cep', '');
      formRef.current?.setFieldValue('empresa_rua', '');
      formRef.current?.setFieldValue('empresa_numero', '');
      formRef.current?.setFieldValue('empresa_complemento', '');
      formRef.current?.setFieldValue('empresa_cidade', '');
      formRef.current?.setFieldValue('empresa_bairro', '');
      formRef.current?.setFieldValue('empresa_estado', '');

      setShowErrorCNAE(false);
    }
  }, []);

  const checkCNAES = useCallback(
    async cnpj => {
      if (cnpj && isEspecialista) {
        // const cnaes_permitidos = [8532500, 8599699, 8511500];
        const cnaes_permitidos = [
          8599604,
          5811500,
          6202333,
          8550302,
          9101500,
          8599699,
        ];
        let found = false;

        cnaes_permitidos.forEach(cnae_permitido => {
          if (cnae_permitido == cnpj.cnae_fiscal) {
            found = true;
          } else {
            if (cnpj.cnaes_secundarias) {
              cnpj.cnaes_secundarias.forEach((cnae_secundario: any) => {
                if (cnae_permitido == cnae_secundario.codigo) {
                  found = true;
                }
              });
            }
          }
        });

        //console.log("cnae_fiscal", cnpj.cnae_fiscal);
        //console.log("cnaes_secundarias", cnpj.cnaes_secundarias);

        if (!found) {
          setShowErrorCNAE(true);
        } else {
          setShowErrorCNAE(false);
        }
      } else {
        setShowErrorCNAE(false);
      }
    },
    [isEspecialista],
  );

  const isValidCPF = useCallback(async cpf => {
    if (typeof cpf !== 'string') return false;
    cpf = cpf.replace(/[\s.-]*/gim, '');

    if (
      !cpf ||
      cpf.length != 11 ||
      cpf == '00000000000' ||
      cpf == '11111111111' ||
      cpf == '22222222222' ||
      cpf == '33333333333' ||
      cpf == '44444444444' ||
      cpf == '55555555555' ||
      cpf == '66666666666' ||
      cpf == '77777777777' ||
      cpf == '88888888888' ||
      cpf == '99999999999'
    ) {
      return false;
    }
    var soma = 0;
    var resto;
    for (var i = 1; i <= 9; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(9, 10))) return false;
    soma = 0;
    for (var i = 1; i <= 10; i++)
      soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;
    if (resto == 10 || resto == 11) resto = 0;
    if (resto != parseInt(cpf.substring(10, 11))) return false;
    return true;
  }, []);

  const getBanks = useCallback(async () => {
    setLoading(true);

    try {
      const response = await api.get(`https://brasilapi.com.br/api/banks/v1`);
      //console.log("response", response.data);

      setBanks(response.data);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const validarPIS = useCallback(pis => {
    var multiplicadorBase = '3298765432';
    var total = 0;
    var resto = 0;
    var multiplicando = 0;
    var multiplicador = 0;
    var digito = 99;

    var numeroPIS = pis.replace(/[^\d]+/g, '');
    //console.log("numeroPIS", numeroPIS);

    if (
      numeroPIS.length !== 11 ||
      numeroPIS === '00000000000' ||
      numeroPIS === '11111111111' ||
      numeroPIS === '22222222222' ||
      numeroPIS === '33333333333' ||
      numeroPIS === '44444444444' ||
      numeroPIS === '55555555555' ||
      numeroPIS === '66666666666' ||
      numeroPIS === '77777777777' ||
      numeroPIS === '88888888888' ||
      numeroPIS === '99999999999'
    ) {
      return false;
    } else {
      for (var i = 0; i < 10; i++) {
        multiplicando = parseInt(numeroPIS.substring(i, i + 1));
        multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
        total += multiplicando * multiplicador;
      }

      resto = 11 - (total % 11);
      resto = resto === 10 || resto === 11 ? 0 : resto;

      digito = parseInt('' + numeroPIS.charAt(10));
      return resto === digito;
    }
  }, []);

  function hasNumber(myString: any) {
    return /\d/.test(myString);
  }

  function hasSpecialCharacters(myString: any) {
    return /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(myString);
  }

  const validarNome = useCallback(input => {
    //console.log("validarNome", input);

    let tmp = input.trim();

    if (tmp.split(' ').length <= 1) {
      return false;
    }

    if (hasNumber(tmp)) {
      return false;
    }

    if (hasSpecialCharacters(tmp)) {
      return false;
    }

    return true;
  }, []);

  return (
    <Container>
      <Content>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Card>
            <h2>Dados Pessoais</h2>

            <WrapRow>
              <WrapRowItem>
                <FormInput
                  name="nome"
                  type="text"
                  placeholder="Nome Completo"
                />
              </WrapRowItem>
            </WrapRow>

            <FormInput
              name="email"
              type="text"
              placeholder="E-mail"
              // disabled
            />

            <WrapRow>
              <WrapRowItem>
                <FormInputMask
                  name="cpf"
                  type="text"
                  placeholder="CPF"
                  mask="999.999.999-99"
                />
              </WrapRowItem>
            </WrapRow>

            <WrapRow>
              <WrapRowItem>
                <FormInput name="rg" type="text" placeholder="RG" />
              </WrapRowItem>

              <WrapRowItem>
                <FormInput
                  name="rg_expedicao_orgao"
                  type="text"
                  placeholder="Órgão"
                />
              </WrapRowItem>

              <WrapRowItem>
                <FormInputMask
                  name="rg_expedicao_data"
                  type="text"
                  placeholder="Emissão"
                  mask="99/99/9999"
                />
              </WrapRowItem>
            </WrapRow>

            <WrapRow>
              <WrapRowItem>
                <FormInputMask
                  name="pis"
                  type="text"
                  placeholder="PIS / PASEP / NIT"
                  mask="999.9999.999-9"
                  onChange={evt => validarPIS(evt.target.value)}
                />
              </WrapRowItem>

              <WrapRowItem>
                <FormInputMask
                  name="data_nascimento"
                  type="text"
                  placeholder="Data Nascimento"
                  mask="99/99/9999"
                />
              </WrapRowItem>
            </WrapRow>

            <WrapRow>
              <WrapRowItem>
                <FormInput
                  name="naturalidade"
                  type="text"
                  placeholder="Naturalidade"
                />
              </WrapRowItem>

              <WrapRowItem>
                <FormInput
                  name="nacionalidade"
                  type="text"
                  placeholder="Nacionalidade"
                />
              </WrapRowItem>
            </WrapRow>

            <WrapRow style={{ marginTop: '15px', marginBottom: '15px' }}>
              <WrapRowItem style={{ minWidth: '215px', marginBottom: '30px' }}>
                <label>Gênero:</label>
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    // input: styles => ({ ...styles }),
                  }}
                  placeholder={''}
                  onChange={handleChangeSelectedSexo}
                  options={[
                    { value: 'M', label: 'Masculino' },
                    { value: 'F', label: 'Feminino' },
                    { value: 'N', label: 'Não-binário/Outros' },
                  ]}
                  value={selectedSexo}
                />
              </WrapRowItem>

              <WrapRowItem style={{ minWidth: '215px', marginBottom: '30px' }}>
                <label>Estado Civil:</label>
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    // input: styles => ({ ...styles }),
                  }}
                  placeholder={''}
                  onChange={handleChangeSelectedEstadoCivil}
                  options={optionsEstadoCivil}
                  value={selectedEstadoCivil}
                />
              </WrapRowItem>
            </WrapRow>

            {/* <FormInput
              name="pis"
              type="text"
              placeholder="PIS"
            />

            <FormInput
              name="pasep"
              type="text"
              placeholder="PASEP"
            /> */}

            {/* <FormInput
              name="nit"
              type="text"
              placeholder="NIT"
            />
            <span>Caso não possua PIS/PASEP, deve se cadastrar no site "www.previdencia.gov.br" para gerar seu NIT.</span> */}
          </Card>

          {/* <h2>Dados Pessoais</h2> */}

          {/* <FormInput
            name="nome_social"
            type="text"
            placeholder="Nome Social"
          /> */}

          <Card>
            <h2>Endereço</h2>

            <WrapRow style={{ marginTop: '15px', marginBottom: '20px' }}>
              <WrapRowItem>
                <label>País:</label>
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    // input: styles => ({ ...styles }),
                  }}
                  placeholder={''}
                  onChange={handleChangeSelectedCountry}
                  options={countries}
                  value={selectedCountry}
                />
              </WrapRowItem>
            </WrapRow>

            {selectedCountry.value === 'BR' ? (
              <WrapRow>
                <WrapRowItem>
                  <FormInputMask
                    name="cep"
                    type="text"
                    placeholder="CEP"
                    mask="99999-999"
                    onChange={evt => searchCEP(evt.target.value)}
                  />
                </WrapRowItem>
              </WrapRow>
            ) : (
              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="cep"
                    type="text"
                    placeholder="Código Postal"
                  />
                </WrapRowItem>
              </WrapRow>
            )}

            <WrapRow>
              <WrapRowItem>
                <FormInput name="rua" type="text" placeholder="Rua" />
              </WrapRowItem>
            </WrapRow>

            <WrapRow>
              <WrapRowItem>
                <FormInput name="numero" type="text" placeholder="Número" />
              </WrapRowItem>

              <WrapRowItem>
                <FormInput
                  name="complemento"
                  type="text"
                  placeholder="Complemento"
                />
              </WrapRowItem>

              <WrapRowItem>
                <FormInput name="bairro" type="text" placeholder="Bairro" />
              </WrapRowItem>
            </WrapRow>

            <WrapRow>
              <WrapRowItem>
                <FormInput name="cidade" type="text" placeholder="Cidade" />
              </WrapRowItem>

              {selectedCountry.value === 'BR' ? (
                <WrapRowItem>
                  <FormInputMask
                    name="estado"
                    type="text"
                    placeholder="Estado"
                    mask="**"
                    onChange={evt => formatUF(evt.target.value)}
                  />
                </WrapRowItem>
              ) : (
                <WrapRowItem>
                  <FormInput name="estado" type="text" placeholder="Estado" />
                </WrapRowItem>
              )}
            </WrapRow>

            {/* <FormInput
              name="referencia_localizacao"
              type="text"
              placeholder="Referência"
            /> */}
          </Card>

          <Card>
            <h2>Contato</h2>

            {selectedCountry.value === 'BR' ? (
              <WrapRow>
                <WrapRowItem>
                  <FormInputMask
                    name="telefone"
                    type="text"
                    placeholder="Telefone"
                    mask="(99) 9999-9999"
                  />
                </WrapRowItem>

                <WrapRowItem>
                  <FormInputMask
                    name="celular"
                    type="text"
                    placeholder="Celular"
                    mask="(99) 99999-9999"
                  />
                </WrapRowItem>
              </WrapRow>
            ) : (
              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="telefone"
                    type="text"
                    placeholder="Telefone"
                  />
                </WrapRowItem>

                <WrapRowItem>
                  <FormInput name="celular" type="text" placeholder="Celular" />
                </WrapRowItem>
              </WrapRow>
            )}
          </Card>

          <Card>
            <h2>Dados Profissionais</h2>

            <WrapRow style={{ marginBottom: '20px' }}>
              <WrapRowItem>
                <label>Tipo:</label>
                <Select
                  styles={{
                    // Fixes the overlapping problem of the component
                    menu: provided => ({ ...provided, zIndex: 9999 }),
                    // input: styles => ({ ...styles }),
                  }}
                  placeholder={''}
                  onChange={handleChangeSelectedTipoColaborador}
                  options={optionsTipoColaborador}
                  value={selectedTipoColaborador}
                />
              </WrapRowItem>
            </WrapRow>

            {selectedTipoColaborador.value === 'INTERNO' && (
              <WrapRow style={{ marginTop: '30px' }}>
                <WrapRowItem>
                  <FormInput
                    name="matricula"
                    type="number"
                    value={matricula}
                    placeholder="Matrícula Colaborador Yduqs"
                    onChange={item => setMatricula(item.target.value)}
                  />
                </WrapRowItem>
              </WrapRow>
            )}

            {/* <FormInput
                name="lattes"
                type="text"
                placeholder="Lattes"
              /> */}
          </Card>

          {selectedTipoColaborador.value === 'PJ' && (
            <Card>
              <h2>Pessoa Jurídica</h2>

              <WrapRow>
                <WrapRowItem>
                  <FormInputMask
                    name="cnpj"
                    type="text"
                    placeholder="CNPJ"
                    mask="99.999.999/9999-99"
                    onChange={evt => searchCNPJ(evt.target.value)}
                  />
                </WrapRowItem>
              </WrapRow>

              {showErrorCNAE && (
                <WrapRow>
                  <WrapRowItem>
                    <div style={{ color: '#900', marginBottom: '25px' }}>
                      * O CNPJ inserido não possui CNAEs permitido pela YDUQS.
                      Entre em contato no caso de dúvidas.
                    </div>
                  </WrapRowItem>
                </WrapRow>
              )}

              <WrapRow>
                <WrapRowItem>
                  <div style={{ color: '#900', marginBottom: '25px' }}>
                    Lista de CNAEs aceitos: <br />
                    <br />
                    85.99-6-04: Treinamento Em Desenvolvimento Profissional E
                    Gerencial <br />
                    58.11-5-00: Edição De Livros <br />
                    62.02-3-33: Desenvolvimento E Licenciamento De Programas De
                    Computador Customizáveis <br />
                    85.50-3-02: Atividades De Apoio À Educação, Exceto Caixas
                    Escolares <br />
                    91.01-5-00: Atividades De Biblioteca E Arquivos <br />
                    85.99-6-99: Outras Atividades De Ensino Não Especificadas
                    Anteriormente
                  </div>
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="razao_social"
                    type="text"
                    placeholder="Razão Social"
                    // disabled={!cnpjNotFound}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInputMask
                    name="empresa_cep"
                    type="text"
                    placeholder="CEP"
                    mask="99999-999"
                    onChange={evt => searchCEP_PJ(evt.target.value)}
                    // disabled={!cnpjNotFound}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="empresa_rua"
                    type="text"
                    placeholder="Rua"
                    // disabled={!cnpjNotFound}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="empresa_numero"
                    type="text"
                    placeholder="Número"
                    // disabled={!cnpjNotFound}
                  />
                </WrapRowItem>

                <WrapRowItem>
                  <FormInput
                    name="empresa_complemento"
                    type="text"
                    placeholder="Complemento"
                    // disabled={!cnpjNotFound}
                  />
                </WrapRowItem>

                <WrapRowItem>
                  <FormInput
                    name="empresa_bairro"
                    type="text"
                    placeholder="Bairro"
                    // disabled={!cnpjNotFound}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow>
                <WrapRowItem>
                  <FormInput
                    name="empresa_cidade"
                    type="text"
                    placeholder="Cidade"
                    // disabled={!cnpjNotFound}
                  />
                </WrapRowItem>

                <WrapRowItem>
                  <FormInputMask
                    name="empresa_estado"
                    type="text"
                    placeholder="Estado"
                    mask="**"
                    onChange={evt => formatUF(evt.target.value, true)}
                    // disabled={!cnpjNotFound}
                  />
                </WrapRowItem>
              </WrapRow>
            </Card>
          )}

          {(selectedTipoColaborador.value === 'PF' ||
            selectedTipoColaborador.value === 'PJ') && (
            <Card>
              <h2>
                Dados Bancários{' '}
                {`${
                  selectedTipoColaborador.value === 'PF'
                    ? '- Pessoa Física'
                    : '- Pessoa Jurídica'
                }`}
              </h2>

              <WrapRow>
                <WrapRowItem>
                  <label>Tipo de Conta Bancária:</label>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedContaPessoaFisica}
                    options={optionsTipoContaBancaria}
                    value={selectedContaPessoaFisica}
                  />
                </WrapRowItem>
              </WrapRow>

              <WrapRow style={{ marginTop: '30px' }}>
                <WrapRowItem>
                  <label>Banco:</label>
                  <Select
                    styles={{
                      // Fixes the overlapping problem of the component
                      menu: provided => ({ ...provided, zIndex: 9999 }),
                      // input: styles => ({ ...styles }),
                    }}
                    placeholder={''}
                    onChange={handleChangeSelectedBank}
                    options={optionsBank}
                    value={selectedBank}
                  />
                </WrapRowItem>
              </WrapRow>

              {/* <WrapRow style={{ marginTop: "30px" }}>
                <WrapRowItem>
                  <FormInput
                    name="banco"
                    type="text"
                    placeholder="Banco"
                  />
                </WrapRowItem>
              </WrapRow> */}

              <WrapRow style={{ marginTop: '30px' }}>
                <WrapRowItem>
                  <FormInput name="agencia" type="text" placeholder="Agência" />
                </WrapRowItem>

                <WrapRowItem>
                  <FormInput
                    name="conta"
                    type="text"
                    placeholder="Conta Corrente"
                  />
                </WrapRowItem>

                <WrapRowItem>
                  <FormInput
                    name="digito_verificador"
                    type="text"
                    placeholder="Dígito verficador"
                    // mask="9"
                  />
                </WrapRowItem>
              </WrapRow>
            </Card>
          )}

          <Button type="submit" loading={loading}>
            {isNewUser ? 'Cadastrar Dados' : 'Atualizar Dados'}
          </Button>
        </Form>
      </Content>
    </Container>
  );
};

export default Profile;
